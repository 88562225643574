/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { DatePickerRenderInput } from 'components/FieldForm/styles';
import { add, isAfter, isBefore, isDate, lastDayOfMonth, sub } from 'date-fns';
import { useFormikContext } from 'formik';
import { IconDate } from 'pages/Calculations/components/Accomplishment/components/ActDialog/ActDialog.style';
import {
  ActCol,
  ActRow,
} from 'pages/Calculations/components/CalculationBasic/components/ParametersDialog/components/ActsTab/ActsTab.style';
import { HandbookContext } from 'pages/Calculations/components/CalculationDirectory/handbook.services';
import React from 'react';
import { ActList } from 'types';
import { formatDateToString, formatToDate } from 'utils/formatDate';
import { statuses } from '../../../helper';
import { IForm } from '../../Parameters';

export const EditableRowAct: React.FC<{ [key: string]: any; row: ActList }> = ({
  calculation,
  row,
  rowIndex,
  parentIndex,
  remove,
}) => {
  const { currentAct, changeAct } = React.useContext(HandbookContext);

  const { values, setFieldValue, setValues } = useFormikContext<IForm>();

  const startDate = React.useMemo(() => {
    return row.startDate;
  }, [row]);

  const endDate = React.useMemo(() => {
    return row.endDate;
  }, [row]);

  /**
   * являются ли даты одинаковыми
   * @param dateA
   * @param dateB
   */
  const isSameDate = (dateA: Date, dateB: Date) =>
    dateA.getDate() === dateB.getDate() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getFullYear() === dateB.getFullYear();

  /**
   * разбирается список дат для вывода на страницу, делается плоская структура
   */
  const unFlatRanges = React.useMemo(() => {
    const fields = values.acts?.actual || [];
    return fields
      .reduce((acc: { start: string | Date; end: string | Date }[], item) => {
        acc.push(
          ...item.fields.flatMap((dates) => ({
            start: dates.startDate || '',
            end: dates.endDate || '',
          })),
        );
        return acc;
      }, [])
      .filter(
        (d) =>
          !isSameDate(d.start as Date, new Date(row.startDate || '')) &&
          !isSameDate(d.end as Date, new Date(row.endDate || '')),
      );
  }, [values]);

  /**
   * массив недоступных дат
   */
  const disabledDates: { start: Date; end: Date }[] = React.useMemo(() => {
    const fields = values.acts?.actual || [];
    if (!fields) return [];
    return unFlatRanges.reduce(
      (disabled, dateRange) => {
        const { start, end } = dateRange;
        disabled.push({ start: new Date(start), end: new Date(end) });
        return disabled;
      },
      [] as { start: Date; end: Date }[],
    );
  }, [startDate, endDate, values]);

  /**
   * Здесь идет расчет
   * вычисляется какое это поле и устанавливает максимальную и минимальную даты
   */
  const disabledRange = React.useCallback(
    (type: 'startDate' | 'endDate' | 'onDate') => {
      const startD = startDate as Date;
      const endD = endDate as Date;

      const sortedDates = [...disabledDates, {} as { start: Date; end: Date }]
        .sort((a, b) => a?.start?.getTime() - b?.start?.getTime())
        .map((d, i, array) => {
          if (i == 0) {
            return { start: null, end: d.start ?? null };
          } else
            return {
              start: array[i - 1].end,
              end: d?.start ?? null,
            };
        });
      const dates: { minDate: null | Date; maxDate: null | Date } = {
        minDate: null,
        maxDate: null,
      };
      if (!startD && !endD) return dates;
      if (sortedDates.length === 1) {
        if (type === 'startDate') {
          if (endD) {
            const date = endD.getDate();
            const year = endD.getFullYear();
            const month = endD.getMonth();
            dates.maxDate = new Date(year, month, date - 1);
          }
        }
        if (type === 'endDate') {
          if (startD) {
            const date = startD.getDate();
            const year = startD.getFullYear();
            const month = startD.getMonth();
            dates.minDate = new Date(year, month, date + 1);
          }
        }
        return dates;
      }
      for (const { start, end } of sortedDates) {
        if (start === null) continue;
        if (type === 'startDate') {
          let d: Date | undefined;
          if (endD) {
            const date = endD.getDate();
            const year = endD.getFullYear();
            const month = endD.getMonth();
            d = new Date(year, month, date - 1);
          }
          if (start === null && endD && isBefore(endD, end)) {
            dates.minDate = add(start, { days: 1 });
            dates.maxDate = d ?? end;
            break;
          } else if (end === null && isAfter(endD as Date, start)) {
            dates.minDate = add(start, { days: 1 });
            dates.maxDate = d ?? end;
            break;
          } else {
            if (isAfter(endD as Date, start) && isBefore(endD as Date, end)) {
              dates.minDate = add(start, { days: 1 });
              dates.maxDate = d ?? end;
              break;
            }
          }
        }
        if (type === 'endDate') {
          let d: Date | undefined;
          if (startD) {
            const date = startD.getDate();
            const year = startD.getFullYear();
            const month = startD.getMonth();
            d = new Date(year, month, date + 1);
          }
          if (start === null && startD && isBefore(startD, end)) {
            dates.minDate = d ?? start;
            dates.maxDate = sub(end, { days: 1 });
            break;
          } else if (end === null && isAfter(startD as Date, start)) {
            dates.minDate = d ?? start;
            dates.maxDate = sub(end, { days: 1 });
            break;
          } else {
            if (
              isAfter(startD as Date, start) &&
              isBefore(startD as Date, end)
            ) {
              dates.minDate = d ?? start;
              dates.maxDate = sub(end, { days: 1 });
              break;
            }
          }
        }
      }
      return dates;
    },
    [disabledDates, startDate, endDate],
  );

  const handleRemove = React.useCallback(
    (updateItem: ActList) => {
      const fields = values.acts?.actual || [];
      const copyFields = [...fields];
      /**
       * функция фильтрации по recordID
       */
      const filter = (i: ActList) => {
        return i.id !== updateItem.id;
      };

      const currentParent = copyFields[parentIndex];
      console.log(currentParent);

      /**
       * новый список для отрисовки, фильтрация
       */
      currentParent.fields = currentParent.fields.filter(filter);

      /* Получение полного списка к удалению */
      const removedArr: ActList[] = values.acts?.removed || [];
      if (updateItem.id === currentAct?.id) {
        const condidate = fields.map((_) => _.fields.find((a) => a.id));
        if (condidate[0]) {
          changeAct?.(condidate[0]);
        } else {
          changeAct?.(null);
        }
      }

      /* пушится новый объект и перезаписывается значение */
      removedArr.push({
        ...updateItem,
      });
      const copyValues = Array.from(values.acts?.actual || []);
      if (copyValues.length) {
        console.log(currentParent);
        copyValues.splice(parentIndex, 1, currentParent);
      }
      setValues(
        {
          ...values,
          acts: {
            ...values.acts,
            actual: copyValues,
            removed: removedArr,
          },
        },
        true,
      );
      remove();
    },
    [rowIndex, parentIndex, currentAct, changeAct, values],
  );
  const checkDate = (
    inputDate: Date | null,
    start: Date | null,
    end: Date | null,
  ) => {
    if (!inputDate || !start || !end) return false;
    return (
      isSameDate(inputDate, start) ||
      (inputDate > start && inputDate < end) ||
      isSameDate(inputDate, end)
    );
  };
  const shouldDisabled = (
    dayOrMonth: unknown,
    render: 'startDate' | 'endDate',
  ) => {
    if (dayOrMonth) {
      if (disabledDates.length) {
        if (render === 'startDate') {
          return disabledDates.some(({ start, end }) =>
            checkDate(dayOrMonth as Date, start, end),
          );
        }
        if (render === 'endDate') {
          return disabledDates.some(({ start, end }) =>
            checkDate(dayOrMonth as Date, start, end),
          );
        }
      }
    }
    return false;
  };

  return (
    <ActRow
      isIntegrate={!!calculation?.integrationInfo}
      active={row.id === currentAct?.id}>
      <ActCol>
        {formatDateToString(new Date(row.onDate || ''), 'dd.MM.yyyy')}
      </ActCol>
      <ActCol>
        <DesktopDatePicker
          value={startDate}
          openTo="year"
          views={['year', 'month', 'day']}
          shouldDisableMonth={(d) => shouldDisabled(d, 'startDate')}
          shouldDisableDate={(d) => shouldDisabled(d, 'startDate')}
          onChange={(e, keyboardInputValue) => {
            const userValue = keyboardInputValue
              ? keyboardInputValue
              : e &&
                  e instanceof Date &&
                  !isNaN(e.getTime()) &&
                  !keyboardInputValue
                ? e.toLocaleDateString()
                : '';

            if (/^\d{2}\.\d{2}\.\d{4}/.test(userValue)) {
              setFieldValue(
                `acts.actual.${parentIndex}.fields.${rowIndex}.startDate` as 'acts.actual.0.fields.0.startDate',
                e as Date,
                true,
              );
              const lastDay = lastDayOfMonth(e as Date);
              const endDate =
                disabledRange('endDate').maxDate &&
                sub(disabledRange('endDate').maxDate as Date, { days: 1 });
              if (
                endDate &&
                isDate(endDate) &&
                e instanceof Date &&
                endDate.getMonth() === e.getMonth()
              ) {
                setFieldValue(
                  `acts.actual.${parentIndex}.fields.${rowIndex}.endDate`,
                  e as Date,
                  true,
                );
              } else {
                if (!isNaN(lastDay.getTime())) {
                  setFieldValue(
                    `acts.actual.${parentIndex}.fields.${rowIndex}.endDate`,
                    e as Date,
                    true,
                  );
                } else {
                  setFieldValue(
                    `acts.actual.${parentIndex}.fields.${rowIndex}.endDate`,
                    e as Date,
                    true,
                  );
                }
              }
            }
          }}
          components={{
            OpenPickerIcon: IconDate,
          }}
          renderInput={(params) => (
            <DatePickerRenderInput
              {...params}
              value={startDate}
              onChange={(e) => {
                e.stopPropagation();
                if (!e.target.value) {
                  setFieldValue(
                    `acts.actual.${parentIndex}.fields.${rowIndex}.startDate`,
                    null,
                    true,
                  );
                  return;
                }
                if (/\^(\d{2})\.(\d{2})\.(\d{4})$/.test(e.target.value)) {
                  const date = new Date(
                    formatToDate(e!.target!.value! as string, 'yyyy-MM-dd')!,
                  );
                  setFieldValue(
                    `acts.actual.${parentIndex}.fields.${rowIndex}.startDate`,
                    !isNaN(date.getTime()) ? date : e.target.value,
                    true,
                  );
                }
              }}
              inputProps={{
                ...params.inputProps,
                style: { padding: '6px 6px 6px 8px' },
                placeholder: 'дд.мм.гггг',
                autoComplete: 'off',
              }}
            />
          )}
        />
      </ActCol>
      <ActCol align={'center'}>&mdash;</ActCol>
      <ActCol>
        <DesktopDatePicker
          value={endDate}
          components={{
            OpenPickerIcon: IconDate,
          }}
          maxDate={disabledRange('endDate').maxDate}
          minDate={disabledRange('endDate').minDate}
          openTo="year"
          views={['year', 'month', 'day']}
          shouldDisableMonth={(d) => shouldDisabled(d, 'endDate')}
          shouldDisableDate={(d) => shouldDisabled(d, 'endDate')}
          onChange={(e: any, keyboardInputValue) => {
            console.log(keyboardInputValue);
            const userValue = keyboardInputValue
              ? keyboardInputValue
              : e && !isNaN(e.getTime()) && !keyboardInputValue
                ? e.toLocaleDateString()
                : '';

            if (/^\d{2}\.\d{2}\.\d{4}/.test(userValue)) {
              setFieldValue(
                `acts.actual.${parentIndex}.fields.${rowIndex}.endDate`,
                e,
                true,
              );
            }
          }}
          renderInput={(params) => (
            <DatePickerRenderInput
              {...params}
              value={endDate}
              onChange={(e) => {
                e.stopPropagation();
                if (!e.target.value) {
                  setFieldValue(
                    `acts.actual.${parentIndex}.fields.${rowIndex}.endDate`,
                    null,
                    true,
                  );
                  return;
                }
                if (/\^(\d{2})\.(\d{2})\.(\d{4})$/.test(e.target.value)) {
                  const date = new Date(
                    formatToDate(e!.target!.value! as string, 'yyyy-MM-dd')!,
                  );
                  setFieldValue(
                    `acts.actual.${parentIndex}.fields.${rowIndex}.endDate`,
                    !isNaN(date.getTime()) ? date : e.target.value,
                    true,
                  );
                }
              }}
              inputProps={{
                ...params.inputProps,
                style: { padding: '6px 6px 6px 8px' },
                placeholder: 'дд.мм.гггг',
                // maxLength,
                // minLength,
                autoComplete: 'off',
              }}
            />
          )}
        />
      </ActCol>
      <ActCol align={'center'}>
        <IconButton onClick={() => handleRemove(row)} color="warning">
          <Delete />
        </IconButton>
      </ActCol>
      {calculation?.integrationInfo && (
        <ActCol color={statuses[row.status!]!.color} align={'center'}>
          {statuses[row.status!]!.title}
        </ActCol>
      )}
    </ActRow>
  );
};
