/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import { Typography } from '@mui/material';
import { FieldArray } from 'formik';
import {
  ActCol,
  ActRow,
  ActTable,
} from 'pages/Calculations/components/CalculationBasic/components/ParametersDialog/components/ActsTab/ActsTab.style';
import React from 'react';
import { CalcData } from 'types';
import { IForm } from '../../Parameters';
import { EditableRowAct } from './EditableRowAct';

export const ActsTab: React.FC<{
  isActs: boolean;
  calculation?: CalcData;
  values: IForm;
}> = ({ calculation, values, isActs }) => {
  return (
    <ActTable>
      <ActRow isIntegrate={!!calculation?.integrationInfo} border paddingTop>
        <ActCol head>Сформирован</ActCol>
        <ActCol head align={'center'}>
          Начало периода
        </ActCol>
        <span />
        <ActCol head align={'center'}>
          Окончание периода
        </ActCol>
        <span />
        {calculation?.integrationInfo && (
          <ActCol head align={'flex-start'}>
            Статус
          </ActCol>
        )}
      </ActRow>
      <FieldArray name={'acts.actual'}>
        {() => {
          return (
            <>
              {values.acts?.actual.map((estimate, estimateIndex) => {
                console.log(estimate);
                return (
                  <React.Fragment
                    key={'estimate' + estimateIndex + estimate.label}>
                    {!!estimate.fields.length && (
                      <Typography
                        style={{ cursor: 'default' }}
                        whiteSpace={'nowrap'}
                        textOverflow={'ellipsis'}
                        width={'100%'}
                        overflow={'hidden'}
                        pt={2}
                        fontSize={14}
                        color={'#7890b2'}>
                        {estimate.label || 'Общие'}
                      </Typography>
                    )}
                    <FieldArray name={`acts.actual.${estimateIndex}.fields`}>
                      {({ remove }) => {
                        return (
                          <>
                            {estimate.fields.map((row, rowIndex) => (
                              <React.Fragment
                                key={'parent' + estimateIndex + estimate.label}>
                                <EditableRowAct
                                  remove={() => remove(rowIndex)}
                                  calculation={calculation}
                                  isActs={isActs}
                                  parentIndex={estimateIndex}
                                  rowIndex={rowIndex}
                                  row={row}
                                />
                              </React.Fragment>
                            ))}
                          </>
                        );
                      }}
                    </FieldArray>
                  </React.Fragment>
                );
              })}
            </>
          );
        }}
      </FieldArray>
    </ActTable>
  );
};
