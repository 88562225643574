/**
 * @author Mr_FabiozZz[mr,fabiozzz@gmail.com]
 */
import { useFormikContext } from 'formik';
import {
  HandbookContext,
  TTabType
} from 'pages/Calculations/components/CalculationDirectory/handbook.services';
import React, { useMemo } from 'react';
import { IForm } from '../Parameters';
import { Item, LeftMenu } from '../Parameters.style';
import { TTab } from '../Parameters.types';

const tabs: {
  name: 'indirect_costs' | 'value_changes' | 'acts';
  value: string;
}[] = [
  {
    name: 'acts',
    value: 'Акты по периодам'
  },
  {
    name: 'indirect_costs',
    value: 'Косвенные затраты'
  },
  {
    name: 'value_changes',
    value: 'Коэффициент изменения стоимости'
  }
];

const Menu: React.FC<{
  tab: TTab;
  setTab: (str: TTab) => void;
  selectedTab: TTabType | null;
  isActs: boolean;
}> = React.memo(({ tab, setTab, isActs, selectedTab }) => {
  const { values } = useFormikContext<IForm>();

  const list = React.useMemo(() => {
    return !isActs ? tabs.filter((tab) => tab.name !== 'acts') : tabs;
  }, [isActs]);
  const { actList } = React.useContext(HandbookContext);

  const disabled = useMemo(() => {
    if (selectedTab === 'executed') {
      if (!actList?.actList.length) {
        return true;
      }
      return values.acts?.removed.length === actList?.actList.length;
    }
    return false;
  }, [actList?.actList.length, selectedTab, values.acts?.removed.length]);

  return (
    <LeftMenu style={{ opacity: disabled ? 0.5 : 1 }}>
      {list.map((item) => {
        return (
          <Item
            key={item.name}
            active={item.name === tab}
            onClick={() => item.name !== tab && !disabled && setTab(item.name)}>
            {item.value}
          </Item>
        );
      })}
    </LeftMenu>
  );
});

Menu.displayName = 'Menu';

export default Menu;
