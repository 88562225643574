import { useGetGroupPositionQuery } from 'api/calculations';
import { useCalcId } from 'hooks/useCalcId';
import { useMemo } from 'react';
import { VORGroupData } from '../../../../../api/calculations/types';
import { Head } from '../Head';
import { TreeNodeRow } from '../TreeNodeRow';
import { TreeNodeContextProvider } from '../TreeNodeRow/context';
import { TreeNodeContextConsumer } from '../TreeNodeRow/context/TreeNodeContext/TreeNodeContext.consumer';
import { StyledBody } from './Body.styles';

export const Body: React.FC<{ isHandbook: boolean }> = ({ isHandbook }) => {
  const calcID = useCalcId();

  const { data } = useGetGroupPositionQuery(
    { calcID, isHandbook },
    { skip: !calcID },
  );

  // const [getGroupPosition, { data }] = useLazyGetGroupPositionQuery();

  function calculateDepth(group: VORGroupData) {
    if (!group.children || group.children.length === 0) {
      return 1;
    } else {
      let maxDepth = 0;
      for (const child of group.children) {
        const depth = calculateDepth(child);
        maxDepth = Math.max(maxDepth, depth);
      }
      return maxDepth + 1;
    }
  }

  const maxDeep = useMemo(() => {
    const result = [];
    if (data?.data) {
      for (const root of data.data) {
        result.push(calculateDepth(root));
      }
    }
    return result;
  }, [data?.data]);

  return (
    <TreeNodeContextProvider
      maxDeep={maxDeep}
      levelIds={{}}
      rowID={undefined}
      level={0}
      rowType="folded"
      hasParent={false}
      rowChildren={data?.data}>
      <TreeNodeContextConsumer>
        {({
          level,
          maxDeep,
          groupIds,
          levelIds: parentIds,
          childrenIds,
          selectedIds,
          formVisible,
          onSetSelectedIds,
          hasParent,
        }) => (
          <>
            <Head isHandbook={isHandbook} />
            <StyledBody>
              {data && (
                <TreeNodeRow
                  isHandbook={isHandbook}
                  maxDeep={maxDeep}
                  levelIds={parentIds}
                  level={level}
                  hasParent={hasParent}
                  rows={data.data}
                  checkboxShowed={formVisible.move}
                  selectedIds={selectedIds}
                  childrenIds={childrenIds}
                  groupIds={groupIds}
                  onSetSelectedIds={onSetSelectedIds}
                />
              )}
            </StyledBody>
          </>
        )}
      </TreeNodeContextConsumer>
    </TreeNodeContextProvider>
  );
};
