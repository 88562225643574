/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Checkbox, MenuItem, Typography } from '@mui/material';
import Button from 'components/Button';
import { useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { formatDate } from '../../../../../Accomplishment/components/CaptionTable/CaptionTable';
import { StyledSelect } from '../../../../../Accomplishment/components/CaptionTable/CaptionTable.style';
import { SelectField } from '../../../../../CalculationBasic/components/ParametersDialog/ParametersDialog.styles';
import { HandbookContext, TTabType } from '../../../../handbook.services';
import { IForm } from '../Parameters';
import { HeaderParameters } from '../Parameters.style';

const Header: React.FC<{
  close: () => void;
  isActs?: boolean;
  selectedTab: TTabType | null;
}> = ({ close, isActs, selectedTab }) => {
  const {
    changeAct,
    currentAct,
    actList: data
  } = React.useContext(HandbookContext);
  const { values, getFieldProps, setFieldValue } = useFormikContext<IForm>();
  // const removedActs = useWatch<IForm>({
  //   name:'acts.removed'
  // })

  const renderAct = useMemo(() => {
    if (!currentAct) return 'Актов нет';
    return formatDate(currentAct);
  }, [currentAct]);

  const renderActs = useMemo(() => {
    const removed = values.acts?.removed.map((_) => _?.id);
    if (!data?.actList) return [];
    if (!removed?.length)
      return data.actList?.map((item) => {
        return (
          <MenuItem
            key={item.id}
            onClick={() => {
              if (currentAct?.id !== item.id) {
                changeAct(item);
              }
            }}
            selected={
              formatDate(item) === renderAct && item.id === currentAct?.id
            }
            value={formatDate(item)}>
            {formatDate(item)}
          </MenuItem>
        );
      });

    return data.actList
      ?.filter((_) => !removed?.includes(_.id))
      .map((item) => {
        return (
          <MenuItem
            key={item.id}
            onClick={() => {
              if (currentAct?.id !== item.id) {
                changeAct(item);
              }
            }}
            selected={
              formatDate(item) === renderAct && item.id === currentAct?.id
            }
            value={formatDate(item)}>
            {formatDate(item)}
          </MenuItem>
        );
      });
  }, [values.acts?.removed, data, renderAct, currentAct?.id, changeAct]);

  const disabled = useMemo(() => {
    if (selectedTab === 'executed') {
      return !renderActs.length && !values.acts?.removed.length;
    }
    return false;
  }, [renderActs.length, selectedTab, values.acts?.removed.length]);
  return (
    <HeaderParameters>
      <div className={'box'}>
        <Button
          onClick={close}
          variant="text"
          type="button"
          sx={{ fontWeight: 500, fontSize: '13px' }}>
          <span style={{ display: 'flex', marginRight: '8px' }}>
            <ArrowBackIcon sx={{ height: '25px', width: '25px' }} />
          </span>
          Назад
        </Button>
        <Typography variant="h1" color="primary.light">
          Изменение параметров расчета {isActs ? 'актов' : 'смет'}
        </Typography>
        {isActs && !!renderActs.length && (
          <>
            <SelectField>
              Текущий акт:
              <StyledSelect
                smallText
                hideBorder
                width={240}
                onClear={() => {
                  alert('clear');
                }}
                nullableValues={[renderAct]}
                SelectProps={{
                  renderValue: () => {
                    const separator = ' — ';
                    const destructiveStr = renderAct.split(separator);
                    destructiveStr[1] = `по ${destructiveStr[1]}`;
                    return 'с ' + destructiveStr.join(separator);
                  },
                  MenuProps: {
                    sx: {
                      maxHeight: 500,
                      width: 240
                    },
                    placeholder: 'Выберите диапазон'
                  },
                  value: renderAct,
                  placeholder: 'Выберите диапазон'
                }}
                value={renderAct}
                placeholder={'Выберите диапазон'}
                fullWidth
                select>
                {renderActs}
              </StyledSelect>
            </SelectField>

            <SelectField
              onChange={(e) => {
                console.log(e);
                setFieldValue('synchronized', !values.synchronized, true);
                // methods.setValue('synchronized', !props.value, { shouldValidate: true });
              }}>
              <Checkbox checked={!!values.synchronized} size={'small'} />
              Применить параметры как в расчете
            </SelectField>
          </>
        )}
      </div>
      <div>
        <Button
          disabled={disabled}
          type={'submit'}
          width={'180px'}
          color={'success'}>
          применить
        </Button>
      </div>
    </HeaderParameters>
  );
};

export default Header;
