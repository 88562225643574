import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Box,
  Button,
  FormControl,
  Radio,
  RadioGroup,
  Stack,
  Typography
} from '@mui/material';
import { api } from 'api/api';
import {
  useCreateCalculationMutation,
  useUploadExcelMutation
} from 'api/calculations';
import { useGetLSRFilesQuery } from 'api/lsr';
import { useWS, useWSCalc } from 'api/web-socket';
import { CircularProgressPercent } from 'components/CircularProgressPercent/CircularProgressPercent';
import Progress from 'components/Progress';
import { Form, FormikProvider } from 'formik';
import { useProjectId } from 'hooks/useProjectId';
import { useSnackbar } from 'notistack';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Accept } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import { CalcDataType } from 'types';
import {
  useCreateTanglProjectMutation,
  useGetModelListQuery,
  useGetStatusIntegrateQuery
} from '../../../../api/tangl';
import {
  ICreateTanglRequest,
  ITangleModel,
  ITangleProjectResponse
} from '../../../../api/tangl/tangl.types';
import UploadForm from '../../../../components/UploadForm';
import { useForm } from '../../../../hooks/useForm';
import { useMutationHandlers } from '../../../../hooks/useMutationHandlers';
import { theme } from '../../../../styles/theme';
import {
  StyledFieldFormDesc,
  StyledFieldFormTitle,
  StyledFormControlLabel,
  StyledFormLabel
} from './CalculationDrawerForm.styles';
import {
  CalculationDrawerFormProps,
  CalculationFormData,
  MethodsItemsType
} from './CalculationDrawerForm.types';
import { validationCalculation } from './CalculationDrawerForm.validation';
import { FileList } from './components';
import TangleDrawer from './components/TangleDrawer';

const methodsItems: MethodsItemsType[] = [
  {
    value: 'handbk',
    label: 'Справочник расценок'
  },
  {
    value: 'grandSmeta',
    label: 'Импорт из XML'
  },
  {
    value: 'tangl',
    label: 'Интеграция с Tangl'
  },
  {
    value: 'basisManual',
    label: 'Калькуляция'
  }
];

// const manualItems: MethodsItemsType[] = [
//   {
//     value: 'resourceManual',
//     label: 'В ручном режиме',
//   },
// ];
//
// const tangle: MethodsItemsType[] = [
// ];
export type TParent = Omit<
  Required<ITangleProjectResponse>,
  'models' | 'folders' | 'name'
>;
export type TCurrentModule = {
  module: ITangleModel;
  parent: TParent;
};

interface ITangleContext {
  setCurrentModel: (model: TCurrentModule | null) => void;
  models: ITangleProjectResponse[];
  open: boolean;
  currentModel: null | TCurrentModule;
  toggle: (flag: boolean) => void;
}

export const TanglContext = createContext<ITangleContext>({
  models: [],
  open: false,
  setCurrentModel: () => {},
  toggle: () => {},
  currentModel: null
});

export const CalculationDrawerForm: React.FC<CalculationDrawerFormProps> = ({
  onClose,
  calculation,
  onFormChange
}) => {
  const { connectionStatus } = useWS();
  const {
    createCalcProgress,
    createCalcStatus,
    createCalcError,
    createCalc,
    clearAll
  } = useWSCalc();

  const isUploaded = useMemo(() => !!createCalcProgress, [createCalcProgress]);

  const projectID = useProjectId();
  const navigation = useNavigate();
  const { t } = useTranslation('user');
  const { enqueueSnackbar } = useSnackbar();
  const { description, title } = calculation || {};
  const [createCalculation, createCalculationResponse] =
    useCreateCalculationMutation();
  const [createTanglProject, createTanglProjectResponse] =
    useCreateTanglProjectMutation();
  const [methodsChecked, setMethodsChecked] =
    React.useState<CalcDataType | null>(null);
  const [error, setError] = useState<boolean>(false);
  const { data, isSuccess } = useGetLSRFilesQuery({ projectID });

  const [modelList, setModelList] = useState<ITangleProjectResponse[]>([]);
  const [currentModel, setCurrentModel] = useState<TCurrentModule | null>(null);
  const [checkTangle, setCheckTangle] = useState<boolean | null>(null);
  const [isErrorList, setIsErrorList] = useState(false);

  const statusInteration = useGetStatusIntegrateQuery();
  const modelTangleList = useGetModelListQuery(undefined, {
    skip: !statusInteration.data?.status
  });

  const dispatch = useAppDispatch();

  const [progressPercent, setProgressPercent] = useState<number>(0);
  const [progressTime, setProgressTime] = React.useState<string>('');
  const [swipeDrawer, setSwipeDrawer] = useState(false);

  const toggleSwipeDrawer = (bool: boolean) => setSwipeDrawer(bool);
  // const { singleTask } = websocketApi;

  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleRefresh = useCallback(() => {
    dispatch(api.util.invalidateTags(['Calculations']));
    onClose(false, true);
  }, [dispatch, onClose]);

  useEffect(() => {
    if (createCalcProgress) {
      setProgressTime!(String(createCalcProgress.remaining));
      setProgressPercent(Math.floor(createCalcProgress.percent * 100));
    }
  }, [createCalcProgress]);

  useEffect(() => {
    if (createCalcStatus) {
      setProgressPercent(0);
      setProgressTime('');
      handleRefresh();
      if (createCalcStatus.id) {
        navigation(
          `/projects/${projectID}/calculation/${createCalcStatus.id}/edit?tab=0`
        );
      }
      clearAll();
    }
  }, [clearAll, createCalcStatus, handleRefresh, navigation, projectID]);

  useEffect(() => {
    if (createCalcError) {
      enqueueSnackbar('Непредвиденная ошибка в файле', { variant: 'error' });
      setProgressPercent(0);
      setProgressTime('');
    }
  }, [createCalcError, enqueueSnackbar]);

  const onSubmit = useCallback(
    (values: CalculationFormData) => {
      if (methodsChecked === 'grandSmeta') {
        if (connectionStatus !== 'Open') {
          enqueueSnackbar(
            'Установка соединения. Повторите попытку через несколько секунд.',
            {
              variant: 'error',
              autoHideDuration: 15000
            }
          );
        } else {
          if (values.lsrIds.length !== 0) {
            createCalc({
              projectID: projectID,
              body: {
                title: values.title,
                description: values.description ?? '',
                type: methodsChecked,
                lsrIds: values.lsrIds
              }
            });
          }
        }
      } else if (methodsChecked === 'tangl' && currentModel) {
        const {
          parent: { companyId: tanglCompanyID, id: tanglProjectID },
          module: tanglModelInfo
        } = currentModel;
        const createProject: ICreateTanglRequest = {
          params: {
            projectID
          },
          body: {
            calc: {
              title: values.title,
              description: values.description ?? null,
              type: methodsChecked
            },
            tanglData: {
              tanglCompanyID,
              tanglProjectID,
              tanglModelInfo
            }
          }
        };
        createTanglProject(createProject);
      } else {
        createCalculation({
          params: {
            projectID
          },
          body: {
            title: values.title,
            description: values.description,
            type: methodsChecked!,
            ...(values.lsrIds.length !== 0 && { lsrIds: values.lsrIds })
          }
        });
      }
    },
    [
      methodsChecked,
      currentModel,
      connectionStatus,
      enqueueSnackbar,
      createCalc,
      projectID,
      createTanglProject,
      createCalculation
    ]
  );

  const initialValues: CalculationFormData = useMemo(
    () => ({
      title: title || '',
      description: description || undefined,
      formationMethod: 'basic',
      lsrIds: []
    }),
    [description, title]
  );

  const { formik } = useForm({
    validationSchema: validationCalculation,
    enableReinitialize: true,
    initialValues,
    onSubmit
  });

  const format = useMemo(() => {
    let format: Accept = {};
    switch (methodsChecked) {
      case 'excel':
        format = {
          'application/vnd.ms-excel': ['.xls'],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
            '.xlsx'
          ]
        };
        break;
      case 'grandSmeta':
        format = {
          'application/xml': ['.xml']
        };
        break;
      default:
        break;
    }
    return format;
  }, [methodsChecked]);

  const [formData, setFormData] = useState<FormData | null>(null);

  const [uploadExcel, uploadExcelResponse] = useUploadExcelMutation();

  useMutationHandlers(uploadExcelResponse, () => {
    enqueueSnackbar(t('success.calculationUpload'), {
      variant: 'success'
    });
  });

  useMutationHandlers(createCalculationResponse, (data) => {
    if (data.type === 'excel' && formData) {
      uploadExcel({
        calcID: data.id,
        projectID,
        body: formData
      });
    }
    onClose(false, true);
  });
  useMutationHandlers(
    createTanglProjectResponse,
    (response) => {
      if (response?.id) {
        navigation(
          `/projects/${projectID}/calculation/${response.id}/edit?tab=0`
        );
      }
      onClose(false, true);
    },
    () => {
      enqueueSnackbar({
        variant: 'error',
        message:
          'Ошибка при добавлении расчета \nНеобходимо обратиться к Tangl',
        autoHideDuration: 3000
      });
    }
  );

  const { values, dirty } = formik;

  useEffect(() => {
    onFormChange(methodsChecked === 'tangl' ? dirty || !!currentModel : dirty);
  }, [dirty, currentModel, methodsChecked, onFormChange]);

  useMutationHandlers(
    modelTangleList,
    (data) => {
      setModelList(data);
      setIsErrorList(false);
      setCheckTangle(false);
    },
    (data) => {
      if ('originalStatus' in data && data.originalStatus === 401) {
        setCheckTangle(true);
      } else {
        setCheckTangle(null);
      }
      setIsErrorList(true);
    }
  );

  useMutationHandlers(createCalculationResponse, (response) => {
    if (response?.id) {
      navigation(
        `/projects/${projectID}/calculation/${response.id}/edit?tab=0`
      );
    }
    onClose(false, true);
  });

  const handleChangeMethodsChecked = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setMethodsChecked(event.target.value as CalcDataType);
  };

  const colorTheme = theme.palette;

  const isSubmitButtonDisabled =
    !methodsChecked ||
    (methodsChecked === 'tangl' &&
      (checkTangle ||
        !currentModel ||
        isErrorList ||
        createTanglProjectResponse.isLoading)) ||
    (methodsChecked === 'excel'
      ? !formData
      : methodsChecked === 'grandSmeta'
        ? values.lsrIds.length === 0
        : false) ||
    createCalculationResponse.isLoading ||
    isUploaded;

  return (
    <FormikProvider value={formik}>
      <TanglContext.Provider
        value={{
          currentModel,
          open: swipeDrawer,
          toggle: toggleSwipeDrawer,
          models: modelList,
          setCurrentModel: (model) => setCurrentModel(model)
        }}>
        <Stack
          component={Form}
          flex={1}
          overflow="auto"
          onSubmit={formik.handleSubmit}>
          <Stack ref={containerRef} flex={1} p={2.5} pb={0} overflow="inherit">
            {createCalculationResponse.isLoading || isUploaded ? (
              <>
                {(createCalculationResponse.isLoading ||
                  createTanglProjectResponse.isLoading) && <Progress />}
                {isUploaded && (
                  <CircularProgressPercent
                    thickness={3.6}
                    value={progressPercent!}
                    time={progressTime!}
                  />
                )}
              </>
            ) : (
              <Box flex="1 1 auto">
                <Stack mb={2}>
                  <Stack spacing={2}>
                    <Stack spacing={2}>
                      <StyledFieldFormTitle
                        version="calculation"
                        name="title"
                        placeholder="Укажите краткое название расчета"
                        multiline
                        minRows={1}
                      />
                      <StyledFieldFormDesc
                        version="calculation"
                        name="description"
                        placeholder="Укажите полное название расчета или его описание"
                        multiline
                        minRows={2}
                      />
                    </Stack>
                    {!calculation && (
                      <Stack spacing={'20px'}>
                        <FormControl>
                          <StyledFormLabel
                            style={{
                              marginBottom: 12,
                              color: '#2b3648',
                              fontSize: 16,
                              fontFamily: 'Roboto',
                              fontWeight: 600,
                              letterSpacing: 0.15
                            }}>
                            Выберите методику ценообразования
                          </StyledFormLabel>
                          {/*<Typography variant="body2" sx={{ fontWeight: 500, mb: '12px' }}>*/}
                          {/*  Базисно-индексный метод*/}
                          {/*</Typography>*/}
                          <RadioGroup name="methodsChecked" value="grandSmeta">
                            <Stack spacing={1.5}>
                              {methodsItems.map((item) => (
                                <StyledFormControlLabel
                                  key={item.value}
                                  value={item.value}
                                  onClick={() =>
                                    item.value === 'tangl' &&
                                    toggleSwipeDrawer(true)
                                  }
                                  checked={methodsChecked === item.value}
                                  control={
                                    <Radio
                                      checkedIcon={<CheckCircleIcon />}
                                      onChange={handleChangeMethodsChecked}
                                      icon={
                                        <CheckCircleIcon
                                          color="disabled"
                                          style={{
                                            color: colorTheme.bg.shades
                                          }}
                                        />
                                      }
                                    />
                                  }
                                  label={item.label}
                                />
                              ))}
                              {/*<Typography variant="body2" sx={{ fontWeight: 500, mb: '12px' }}>*/}
                              {/*  Ресурсный метод*/}
                              {/*</Typography>*/}
                              {/*{manualItems.map((item) => (*/}
                              {/*  <StyledFormControlLabel*/}
                              {/*    key={item.value}*/}
                              {/*    value={item.value}*/}
                              {/*    checked={methodsChecked === item.value}*/}
                              {/*    control={*/}
                              {/*      <Radio*/}
                              {/*        checkedIcon={<CheckCircleIcon />}*/}
                              {/*        onChange={handleChangeMethodsChecked}*/}
                              {/*        icon={*/}
                              {/*          <CheckCircleIcon color="disabled" style={{ color: colorTheme.bg.shades }} />*/}
                              {/*        }*/}
                              {/*      />*/}
                              {/*    }*/}
                              {/*    label={item.label}*/}
                              {/*  />*/}
                              {/*))}*/}
                              {/*{checkTangle !== null && (*/}
                              {/*  <>*/}
                              {/*    <Typography variant="body2" sx={{ fontWeight: 500, mb: '12px' }}>*/}
                              {/*      Интеграции*/}
                              {/*    </Typography>*/}
                              {/*    {tangle.map((item) => (*/}
                              {/*      <StyledFormControlLabel*/}
                              {/*        key={item.value}*/}
                              {/*        value={item.value}*/}
                              {/*        disabled={!!checkTangle}*/}
                              {/*        onClick={() => toggleSwipeDrawer(true)}*/}
                              {/*        checked={methodsChecked === item.value}*/}
                              {/*        control={*/}
                              {/*          <Radio*/}
                              {/*            checkedIcon={<CheckCircleIcon />}*/}
                              {/*            onChange={handleChangeMethodsChecked}*/}
                              {/*            icon={*/}
                              {/*              checkTangle ? (*/}
                              {/*                <WarningAmberIcon color={'warning'} />*/}
                              {/*              ) : (*/}
                              {/*                <CheckCircleIcon*/}
                              {/*                  color="disabled"*/}
                              {/*                  style={{ color: colorTheme.bg.shades }}*/}
                              {/*                />*/}
                              {/*              )*/}
                              {/*            }*/}
                              {/*          />*/}
                              {/*        }*/}
                              {/*        label={item.label}*/}
                              {/*      />*/}
                              {/*    ))}*/}
                              {checkTangle && checkTangle !== null && (
                                <Typography
                                  fontSize={12}
                                  variant={'caption'}
                                  sx={{ marginTop: '0 !important' }}
                                  color={'#ED6C02'}>
                                  Связь с Tangl потеряна. Обратитесь к
                                  администратору
                                </Typography>
                              )}
                              {/*  </>*/}
                              {/*)}*/}

                              {methodsChecked === 'tangl' && (
                                <>
                                  {!currentModel ? (
                                    <Typography
                                      onClick={() => toggleSwipeDrawer(true)}
                                      color={(theme1) =>
                                        theme1.palette.primary.main
                                      }
                                      variant="body2"
                                      sx={{
                                        fontWeight: 500,
                                        mb: '12px',
                                        cursor: 'pointer'
                                      }}>
                                      {/*Выбрано: NAV-IPI-ET1_E07-ZZZ-M3D-EST_v1NAV-IPI-ET1_E07-ZZZ-M3D-EST_v1*/}
                                      Выберите проект для обработки
                                    </Typography>
                                  ) : (
                                    <>
                                      <Typography
                                        variant="body2"
                                        sx={{ fontWeight: 500, mb: '12px' }}>
                                        Выбрано:
                                      </Typography>
                                      <Typography
                                        variant="subtitle2"
                                        sx={{ fontWeight: 500, mb: '12px' }}>
                                        {currentModel.module.name}
                                      </Typography>
                                    </>
                                  )}
                                </>
                              )}
                            </Stack>
                          </RadioGroup>
                        </FormControl>
                      </Stack>
                    )}
                  </Stack>
                  {!calculation && (
                    <Stack>
                      {methodsChecked === 'excel' && (
                        <UploadForm
                          errorsMSG={null}
                          error={error}
                          setError={setError}
                          setFormData={setFormData}
                          format={format}
                          mode={'xls'}
                          isLoading={uploadExcelResponse.isLoading}
                          isWS={false}
                        />
                      )}
                    </Stack>
                  )}
                </Stack>
                {methodsChecked === 'grandSmeta' && (
                  <>
                    {isSuccess ? <FileList data={data.files} /> : <Progress />}
                  </>
                )}
                {methodsChecked === 'tangl' && <TangleDrawer />}
              </Box>
            )}
          </Stack>
          <Stack p={2.5} direction="row" spacing={2} pt={2.5} mt="auto">
            <Button
              type="submit"
              color="success"
              fullWidth
              disabled={isSubmitButtonDisabled}>
              {!calculation ? 'Добавить' : 'Сохранить'}
            </Button>
            <Button
              fullWidth
              onClick={() =>
                onClose(
                  isUploaded
                    ? false
                    : dirty || (methodsChecked === 'tangl' && !!currentModel)
                )
              }>
              Закрыть
            </Button>
          </Stack>
        </Stack>
      </TanglContext.Provider>
    </FormikProvider>
  );
};
