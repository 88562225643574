/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import ClearIcon from '@mui/icons-material/Clear';
import { Box, Typography } from '@mui/material';
import {
  ICoefficientData,
  OrNull
} from 'api/references/estimates/estimates.types';
import { ToggleButton } from 'components/ToggleButton';
import { ToggleButtonGroup } from 'components/ToggleButtonGroup';
import { Field, FieldProps, useFormikContext } from 'formik';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { PopperComponent } from 'pages/Administration/AdminReferences/Prices/useTableData';
import { StyledClearableTextField } from 'pages/Calculations/components/CalculationBasic/components/ParametersDialog/components/TabComponent/TabComponent.styles';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import {
  clearData,
  getBaseName,
  getInputProps,
  operation,
  validate
} from '../../../helper';
import { InformIcon } from '../../InformIcon/InformIcon';
import { IForm } from '../../Parameters';
import { Badge, DelimetrRow, Table } from '../../Parameters.style';
import { TTab } from '../../Parameters.types';
import { ListRow } from './ListRow';

type TProps = {
  closeForm: () => void;
  tab: TTab;
  values: IForm;
  mapKz: any;
  mapIndex: any;
};

export const CoefficientsTable: React.FC<TProps> = ({
  closeForm,
  tab,
  values,
  mapKz,
  mapIndex
}) => {
  const { setFieldValue, getFieldProps, validateField, dirty } =
    useFormikContext<IForm>();

  const modifyKey = React.useMemo(() => {
    return tab === 'value_changes' ? 'index' : 'kz';
  }, [tab]);
  const base = values[modifyKey].global;
  const isPercent = !!base?.isPercent;
  const disabledPZ = !!base?.fot || !!base?.mim || !!base?.mtr;
  const disabledRest = !!base?.pz;

  const refPz = React.useRef<OrNull<HTMLElement>>(null);
  const refFot = React.useRef<OrNull<HTMLElement>>(null);
  const refMim = React.useRef<OrNull<HTMLElement>>(null);
  const refMtr = React.useRef<OrNull<HTMLElement>>(null);

  const onChange = (modKey: 'index' | 'kz', record: any, name: string) => {
    validateField(name).finally(() => {
      updateAllFromGlobal(
        {
          // ...node,
          ...record
        },
        modKey
      );
      setFieldValue(`${modifyKey}.global.changedPz`, false);
      setFieldValue(`${modifyKey}.global.changedFot`, false);
      setFieldValue(`${modifyKey}.global.changedMim`, false);
      setFieldValue(`${modifyKey}.global.changedMtr`, false);
    });
  };
  const { openConfirm, ConfirmDialog } = useConfirmDialog({
    handleConfirm: (confirm) => {
      if (confirm) {
        closeForm();
      }
    }
  });

  const handleCloseForm = React.useCallback(() => {
    if (dirty) {
      openConfirm();
    } else {
      closeForm();
    }
  }, [dirty]);

  const recursiveUpdate = React.useCallback(
    (
      arr: ICoefficientData[],
      record: Record<string, any>,
      isPercent?: boolean
    ) => {
      const name = Object.keys(record)[0];
      const value = Object.values(record)[0];
      const getValue = (
        item: ICoefficientData,
        val: any,
        onlyPercent: boolean
      ) => operation(val, item.isPercent ? '%' : '', onlyPercent);
      const result = arr.map((item) => {
        const itemPercent = item.isPercent;
        let copyItem = { ...item, ...record };
        // if (isPercent !== undefined) {
        console.log('isPercent from props function', isPercent);
        console.log('isPercent from props item', item.isPercent);

        // if (!copyItem[name as keyof typeof copyItem]) {
        // }

        const isUpdatedField = (
          key: keyof typeof copyItem,
          el: typeof copyItem
        ) => {
          return !!isPercent === !!itemPercent
            ? (el?.[key] as string | number | null)
            : operation(el?.[key] as any, el.isPercent ? '%' : '', false);
        };

        if (isPercent === undefined) {
          copyItem = {
            ...copyItem,
            changedPz: false,
            changedFot: false,
            changedMim: false,
            changedMtr: false,

            [name]: value
          };
        } else {
          copyItem = {
            ...copyItem,
            changedPz: false,
            changedFot: false,
            changedMim: false,
            changedMtr: false,
            pz: isUpdatedField('pz', copyItem),
            fot: isUpdatedField('fot', copyItem),
            mim: isUpdatedField('mim', copyItem),
            mtr: isUpdatedField('mtr', copyItem),
            [name]: isUpdatedField(name as keyof typeof copyItem, copyItem)
          };
        }
        // }
        if (copyItem.children?.length) {
          // console.log(recursiveUpdate(copyItem.children, record));
          copyItem.children = recursiveUpdate(
            copyItem.children,
            record,
            isPercent
          );
        }
        return copyItem;
      });
      return result;
    },
    []
  );
  const updateAllFromGlobal = React.useCallback(
    (
      record: Record<string, any>,
      modKey: 'kz' | 'index',
      isPercent?: boolean
    ) => {
      setFieldValue(
        `${modKey}.coefficients`,
        recursiveUpdate(values[modKey].coefficients, record, isPercent)
      );
    },
    [values]
  );

  const getHelpIcon = React.useCallback(() => {
    const global = getFieldProps(
      getBaseName(`${modifyKey}.global.global`)
    ).value;
    const coefficients = getFieldProps(
      getBaseName(`${modifyKey}.coefficients.coefficients`)
    ).value;
    const firstChildCondition = coefficients.some(
      (item: ICoefficientData) =>
        item.changedPz || item.changedFot || item.changedMim || item.changedMtr
    );
    const condition = firstChildCondition;
    // const actualMap = modifyKey === 'kz'?mapKz:mapIndex;

    return condition ? <InformIcon /> : null;
  }, [modifyKey, values]);
  return (
    <Table>
      <Typography whiteSpace={'nowrap'} variant={'body2'}>
        Наименование
      </Typography>
      <Typography whiteSpace={'nowrap'} variant={'body2'} align={'center'}>
        К-т / %
      </Typography>
      <Typography whiteSpace={'nowrap'} variant={'body2'} align={'center'}>
        ПЗ
      </Typography>
      <Typography whiteSpace={'nowrap'} variant={'body2'} align={'center'}>
        ФОТ ОР
      </Typography>
      <Typography whiteSpace={'nowrap'} variant={'body2'} align={'center'}>
        МиМ
      </Typography>
      <Typography whiteSpace={'nowrap'} variant={'body2'} align={'center'}>
        МТР
      </Typography>
      <span></span>
      <DelimetrRow />

      <Box gap={'4px'} display={'flex'} alignItems={'center'}>
        <Badge isGlobal>ALL</Badge>
        Глобальный коэффициент
      </Box>
      {/* {modifyKey === 'index' ? ( */}
      {/* <React.Fragment key={'index'}> */}
      <Field
        key={`${modifyKey}.isPercent`}
        name={`${modifyKey}.global.isPercent`}
        render={({ field }: FieldProps) => {
          return (
            <ToggleButtonGroup
              exclusive
              fullWidth
              onChange={(_: any, value) => {
                const node = getFieldProps(`${modifyKey}.global`).value;
                if (
                  (node.isPercent && value === '%') ||
                  (!node.isPercent && value === 'К')
                )
                  return;
                const isPercent = value === '%';
                const newNode = {
                  pz: operation(node.pz, value),
                  fot: operation(node.fot, value),
                  mim: operation(node.mim, value),
                  mtr: operation(node.mtr, value),
                  isPercent
                };
                setFieldValue(`${modifyKey}.global`, newNode);
                updateAllFromGlobal({ isPercent }, modifyKey, isPercent);
              }}>
              {['К', '%'].map((groupButton) => {
                // const selected = groupButton === '%' && row.isPercent;
                const selected = groupButton === 'К' ? !isPercent : !!isPercent;
                return (
                  <ToggleButton
                    key={groupButton}
                    value={groupButton}
                    selected={selected}>
                    {groupButton}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          );
        }}
      />

      <Field
        key={`${modifyKey}.pz`}
        name={`${modifyKey}.global.pz`}
        // shouldUpdate={(nextProps: any, prevProps: any) => {
        //   console.log(nextProps, prevProps);
        //   return nextProps.value !== prevProps.value;
        // }}
        // control={methods.control}
        validateOnChange={true}
        validate={validate}
        render={({ field, form, meta }: FieldProps) => {
          const nodeOrigin = getFieldProps(`${modifyKey}.global`).value;
          const message = (form as any).errors?.[modifyKey]?.global?.pz;
          const isEmpty = form.initialValues?.[modifyKey]?.global?.pz;

          return (
            <>
              <NumericFormat
                {...field}
                inputRef={refPz}
                onChange={(e) => {
                  field.onChange(e);
                  onChange(
                    modifyKey,
                    { pz: e.target.value, fot: '', mim: '', mtr: '' },
                    field.name
                  );
                }}
                InputProps={{
                  ...getInputProps(
                    disabledPZ,
                    nodeOrigin.changedPz && !field.value,
                    isEmpty
                  ),
                  ref: refPz
                }}
                customInput={StyledClearableTextField}
                // isAllowed={(values) => {
                //   const { formattedValue } = values;
                //   return /^(\d{0,20})(?:,\d{0,6})?%?$/.test(formattedValue);
                //   // return floatValue < MAX_LIMIT;
                // }}
                placeholder={isPercent ? '%' : 'К'}
                suffix={isPercent ? '%' : ''}
                allowNegative={false}
                thousandSeparator={' '}
                decimalSeparator=","
                decimalScale={15}
                // error={!!fieldState.error}
                onClear={() => setFieldValue(field.name, '')}
              />
              <PopperComponent message={message} refItem={refPz.current} />
            </>
          );
        }}
      />
      <Field
        key={`${modifyKey}.fot`}
        name={(`${modifyKey}.global` + '.fot') as 'index.global.fot'}
        // shouldUpdate={(nextProps: any, prevProps: any) => nextProps.value !== prevProps.value}
        // control={methods.control}
        validateOnChange={true}
        validate={validate}
        render={({ field, form, meta }: FieldProps) => {
          // console.log(form.errors);
          const nodeOrigin = getFieldProps(`${modifyKey}.global`).value;
          const message = (form as any).errors?.[modifyKey]?.global?.fot;
          // console.log(message);
          // console.log(refFot);
          const isEmpty = form.initialValues?.[modifyKey]?.global?.fot;
          return (
            <>
              <NumericFormat
                {...field}
                allowNegative={false}
                onChange={(e) => {
                  // setFieldValue(field.name, e.target.value, true);

                  // const node = getDefaultNode(getFieldProps(`${modifyKey}.global`).value);
                  field.onChange(e);
                  onChange(
                    modifyKey,
                    { fot: e.target.value, pz: '' },
                    field.name
                  );
                }}
                InputProps={{
                  ...getInputProps(
                    disabledRest,
                    nodeOrigin.changedFot && !field.value,
                    isEmpty
                  ),
                  ref: refFot
                }}
                value={field.value}
                // inputRef={ref}
                customInput={StyledClearableTextField}
                // isAllowed={(values) => {
                // const { formattedValue } = values;
                // return /^-?(\d{0,20})(?:,\d{0,6})?%?$/.test(formattedValue);
                // return floatValue < MAX_LIMIT;
                // }}
                thousandSeparator={' '}
                placeholder={isPercent ? '%' : 'К'}
                suffix={isPercent ? '%' : ''}
                decimalSeparator=","
                decimalScale={15}
                error={!!message}
                onClear={() => setFieldValue(field.name, '')}
              />
              <PopperComponent message={message} refItem={refFot.current} />
            </>
          );
        }}
      />
      <Field
        key={`${modifyKey}.mim`}
        name={(`${modifyKey}.global` + '.mim') as 'index.global.mim'}
        // shouldUpdate={(nextProps: any, prevProps: any) => nextProps.value !== prevProps.value}
        // control={methods.control}
        validateOnChange={true}
        validate={validate}
        render={({ field, form }: FieldProps) => {
          const nodeOrigin = getFieldProps(`${modifyKey}.global`).value;
          const message = (form as any).errors?.[modifyKey]?.global?.mim;
          const isEmpty = form.initialValues?.[modifyKey]?.global?.mim;
          return (
            <>
              <NumericFormat
                {...field}
                // inputRef={ref}
                onChange={(e) => {
                  field.onChange(e);
                  onChange(
                    modifyKey,
                    { mim: e.target.value, pz: '' },
                    field.name
                  );
                }}
                InputProps={{
                  ...getInputProps(
                    disabledRest,
                    nodeOrigin.changedMim && !field.value,
                    isEmpty
                  ),
                  ref: refMim
                }}
                customInput={StyledClearableTextField}
                // isAllowed={(values) => {
                //   const { formattedValue } = values;
                //   return /^(\d{0,20})(?:,\d{0,6})?%?$/.test(formattedValue);
                //   // return floatValue < MAX_LIMIT;
                // }}
                placeholder={isPercent ? '%' : 'К'}
                suffix={isPercent ? '%' : ''}
                allowNegative={false}
                thousandSeparator={' '}
                decimalSeparator=","
                decimalScale={15}
                // error={!!fieldState.error}
                onClear={() => setFieldValue(field.name, '', true)}
              />
              <PopperComponent message={message} refItem={refMim.current} />
            </>
          );
        }}
      />
      <Field
        name={`${modifyKey}.global.mtr`}
        key={`${modifyKey}.mtr`}
        // shouldUpdate={(nextProps: any, prevProps: any) => nextProps.value !== prevProps.value}
        validateOnChange={true}
        validate={validate}
        render={({ field, form }: FieldProps) => {
          const nodeOrigin = getFieldProps(`${modifyKey}.global`).value;
          const message = (form as any).errors?.[modifyKey]?.global?.mtr;
          const isEmpty = form.initialValues?.[modifyKey]?.global?.mtr;

          return (
            <>
              <NumericFormat
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  onChange(
                    modifyKey,
                    { mtr: e.target.value, pz: '' },
                    field.name
                  );
                }}
                // inputRef={ref}
                InputProps={{
                  ...getInputProps(
                    disabledRest,
                    nodeOrigin.changedMtr && !field.value,
                    isEmpty
                  ),
                  ref: refMtr
                }}
                customInput={StyledClearableTextField}
                // isAllowed={(values) => {
                //   const { formattedValue } = values;
                //   return /^(\d{0,20})(?:,\d{0,15})?%?$/.test(formattedValue);
                //   // return floatValue < MAX_LIMIT;
                // }}
                placeholder={isPercent ? '%' : 'К'}
                suffix={isPercent ? '%' : ''}
                allowNegative={false}
                thousandSeparator={' '}
                decimalSeparator=","
                decimalScale={15}
                // error={!!fieldState.error}
                onClear={() => {
                  setFieldValue(field.name, '');
                  // field.onChange('');
                }}
              />
              <PopperComponent message={message} refItem={refMtr.current} />
            </>
          );
        }}
      />
      <Box
        key={`${modifyKey}.btn`}
        position={'relative'}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}>
        <>
          {getHelpIcon()}
          <ClearIcon
            sx={{ position: 'relative', right: '-15px' }}
            onClick={() => {
              const update = {
                ...getFieldProps(`${modifyKey}.global`).value,
                ...clearData
              };
              setFieldValue(`${modifyKey}.global`, update, true);
            }}
            cursor={'pointer'}
            color={'secondary'}
          />
        </>
      </Box>
      {tab === 'indirect_costs' && (
        <ListRow
          key={'indirect_costs'}
          mapKz={mapKz}
          mapIndex={mapIndex}
          modifyKey={'kz'}
          list={values[modifyKey].coefficients}
          name={''}
        />
      )}
      {tab === 'value_changes' && (
        <ListRow
          key={'value_changes'}
          mapKz={mapKz}
          mapIndex={mapIndex}
          modifyKey={'index'}
          list={values[modifyKey].coefficients}
          name={''}
        />
      )}
    </Table>
  );
};
