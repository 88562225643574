/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */
import { Box, Dialog } from '@mui/material';
import {
  useDeleteActMutation,
  useLazySyncCoefficientsActQuery,
  useUpdateActMutation
} from 'api/params';
import { LabelsGroup } from 'api/params/params.types';
import {
  useGetActCoefficientsCalculationQuery,
  useGetCoefficientsCalculationQuery,
  useUpdateActCoefficientsCalculationMutation,
  useUpdateCoefficientsCalculationMutation
} from 'api/references/estimates';
import {
  CoeffType,
  ICoefficientData,
  IResponseCoefficients,
  IUpdateData,
  OrNull
} from 'api/references/estimates/estimates.types';
import { Formik } from 'formik';
import { useCalcId } from 'hooks/useCalcId';
import { enqueueSnackbar } from 'notistack';
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useSearchParams } from 'react-router-dom';
import { ActList, CalcData } from 'types';
import { formatDateToString, toLocalString } from 'utils/formatDate';
import { HandbookContext, TTabType } from '../../../handbook.services';
import { checkDates, findAndReplaceNodeInTreeArray, toValue } from '../helper';
import { Form } from './Form/Form';
import { popperSetup } from './Parameters.types';

export interface IForm {
  index: {
    coefficients: ICoefficientData[];
    global: ICoefficientData;
  };
  kz: {
    coefficients: ICoefficientData[];
    global: ICoefficientData;
  };
  acts?: {
    actual: LabelsGroup[];
    removed: ActList[];
  };
  synchronized?: OrNull<boolean>;
}

const initGlobalParams: ICoefficientData = {
  fot: '',
  id: null,
  rowType: 'FOLDER',
  isPercent: null,
  lvl: null,
  mim: '',
  mtr: '',
  pz: '',
  rowID: 0,
  title: null,
  type: null,
  children: []
};
const defaultValues: IForm = {
  index: {
    coefficients: [],
    global: initGlobalParams
  },
  kz: {
    coefficients: [],
    global: initGlobalParams
  },
  acts: undefined,
  synchronized: undefined
};

function objectFromArray(arr: string[]) {
  const result: { [key: string]: string | boolean } = {};
  for (const str of arr) {
    if (str.startsWith('changed')) {
      result[str] = true;
    }
  }
  return result;
}

// function getInputProps(disabled: boolean, showEndAdornment: boolean, values:IForm,modKey:'kz'|'index',) {

const modifyDataSubmit = (
  data: ICoefficientData,
  type: CoeffType
): IUpdateData => {
  const { children, title, ...row } = data;
  // if (row.children.length) {
  //   row.children = row.children.map((item) => modifyDataSubmit(item, type)) as any;
  // }
  return {
    ...row,
    rowID: row.rowID || null,
    type,
    isPercent: !!row.isPercent,
    fot: toValue(row.fot),
    mim: toValue(row.mim),
    mtr: toValue(row.mtr),
    pz: toValue(row.pz),
    deleted: false
  };
};
const flatChildren = (data: ICoefficientData[]): ICoefficientData[] => {
  const result: ICoefficientData[] = [];
  data.forEach((item) => {
    result.push(item);
    if (item.children.length) {
      result.push(...flatChildren(item.children));
    }
  });
  return result;
};

interface Test_IResponseCoefficients {
  index: {
    global: ICoefficientData;
    coefficients: Record<string, ICoefficientData>;
  };
  kz: {
    global: ICoefficientData;
    coefficients: Record<string, ICoefficientData>;
  };
}

const test_initialData = {
  kz: {
    global: {},
    coefficients: {}
  },
  index: {
    global: {},
    coefficients: {}
  }
};

function test_modifyDataFromServer(
  data: IResponseCoefficients & {
    synchronized?: boolean;
  },
  isActs?: boolean,
  actList?: ActList[]
) {
  const acts = actList ? generateActs(actList) : [];
  const result: Test_IResponseCoefficients = {
    kz: {
      global: { ...data.kz?.global },
      coefficients: {}
    },
    index: {
      global: { ...data.index?.global },
      coefficients: {}
    },
    ...(isActs && {
      acts: {
        actual: acts,
        removed: []
      },
      synchronized: data?.synchronized ?? null
    })
  };
  const flatChildren = (
    coefArr: ICoefficientData[],
    key: 'index' | 'kz',
    name = ''
  ) => {
    coefArr.forEach((item, index) => {
      // console.log(name)
      const newName = !name ? key + ':' + index : name + ':' + index;

      result[key].coefficients[newName] = item;
      if (item.children && item.children.length) {
        flatChildren(item.children, key, newName + ':' + 'children');
      }
    });
  };
  console.log(data);
  flatChildren(data.kz.coefficients, 'kz');
  flatChildren(data.index.coefficients, 'index');

  return result;
}

function generateActs(acts: ActList[]) {
  return acts?.reduce((acc, prev) => {
    // const title = prev.estimateName || 'Общие';
    const label =
      'Акты за ' + formatDateToString(new Date(prev.onDate || ''), 'yyyy');

    const idxGroup = acc.findIndex((_) => _.label === label);
    //
    // prev.forEach((_) => {

    const update = {
      ...prev,
      onDate: new Date(prev.onDate || ''),
      startDate: new Date(prev.startDate || ''),
      endDate: new Date(prev.endDate || '')
    };
    if (idxGroup >= 0) {
      acc[idxGroup].fields = [...acc[idxGroup].fields, update];
    } else {
      acc.push({ label, fields: [update] });
    }
    // });
    return acc;
  }, [] as LabelsGroup[]);
}

const Parameters: React.FC<{
  open: boolean;
  close: () => void;
  isActs: boolean;
  calculation?: CalcData;
}> = ({ open, close, isActs, calculation }) => {
  // const { tableData } = useContext(CalculationDirectoryContext);
  const [searchParams] = useSearchParams();
  const [selectedTab] = useState<TTabType | null>(
    searchParams.get('tab') as TTabType | null
  );
  const calcID = useCalcId();

  const [setupActs, setSetupActs] = React.useState(false);

  const { actList, currentAct, changeAct } = React.useContext(HandbookContext);

  const { data: coeffData } = useGetCoefficientsCalculationQuery(calcID, {
    skip: !calcID || isActs
  });
  console.log(currentAct);
  const { data: coeffActData } = useGetActCoefficientsCalculationQuery(
    {
      calcID,
      actID: currentAct?.id!
    },
    { skip: !currentAct || (!calcID && !currentAct && !isActs) || !open }
  );
  // const { data: calculationParameters } = useGetCoefficientsCalculationQuery(calcID, { skip: !calcID });
  const calculationParameters = useMemo(() => {
    return isActs
      ? {
          ...(coeffActData || {}).coefficients,
          synchronized: coeffActData?.synchronized ?? null
        }
      : coeffData;
  }, [isActs, coeffActData, coeffData]);

  const [updateCalculation, { data: updateResponse }] =
    useUpdateCoefficientsCalculationMutation();

  const [updateActCalculation, { data: updateActResponse }] =
    useUpdateActCoefficientsCalculationMutation();

  const [updateAct] = useUpdateActMutation();

  const [deleteAct] = useDeleteActMutation();

  const [syncAct] = useLazySyncCoefficientsActQuery();

  const mapParentsIndex = useRef(new Map<number, ICoefficientData>());
  const mapParentsKz = useRef(new Map<number, ICoefficientData>());

  const [coefficientsData, setCoefficientsData] = useState<
    IResponseCoefficients & {
      acts?: IForm['acts'];
    }
  >(defaultValues);

  const modifyResponse = useCallback(
    (resp: IResponseCoefficients): IForm => {
      const globalParameters = {
        index: {
          changedPz: false,
          changedFot: false,
          changedMim: false,
          changedMtr: false
        },
        kz: {
          changedPz: false,
          changedFot: false,
          changedMim: false,
          changedMtr: false
        }
      };

      function findParent(id: number, map: Map<number, ICoefficientData>) {
        if (map.has(id)) {
          return map.get(id);
        }
      }

      // function differenceV2(
      //   data: ICoefficientData,
      //   map: Map<number, ICoefficientData>,
      //   arr: ICoefficientData[],
      //   type: 'index' | 'kz',
      // ) {
      //   const parent = data.parentID ? findParent(data.parentID, map) : null;
      //   if (parent) {
      //     const isDifferent = parent.pz !== data.pz|| parent.fot !== data.fot|| parent.mim !== data.mim|| parent.mtr !== data.mtr;
      //
      //       globalParameters[type].changed = isDifferent;
      //
      //     if (isDifferent) {
      //       findAndReplaceNodeInTreeArray(arr, data.rowID ?? data.id, {
      //         changed: isDifferent,
      //       } as any);
      //       map.set(data.rowID ?? data.id, {
      //         ...data,
      //         changed: isDifferent,
      //       });
      //
      //       let currentParent: ICoefficientData | null = parent;
      //       while (currentParent) {
      //         findAndReplaceNodeInTreeArray(arr, currentParent.rowID, {
      //           changed: isDifferent,
      //         } as any);
      //         if (currentParent.parentID !== undefined && currentParent.parentID !== null) {
      //           currentParent = findParent(currentParent.parentID, map)!;
      //         } else {
      //           currentParent = null;
      //         }
      //       }
      //     }
      //   }
      //   for (const child of data.children) {
      //     difference(child, map, arr, type);
      //   }
      // }
      function difference(
        data: ICoefficientData,
        map: Map<number, ICoefficientData>,
        arr: ICoefficientData[],
        type: 'index' | 'kz'
      ) {
        const parent = data.parentID ? findParent(data.parentID, map) : null;
        if (parent) {
          const isDifferentPz = parent.pz !== data.pz;
          const isDifferentFot = parent.fot !== data.fot;
          const isDifferentMim = parent.mim !== data.mim;
          const isDifferentMtr = parent.mtr !== data.mtr;

          if (type === 'kz') {
            if (isDifferentPz) globalParameters.kz.changedPz = isDifferentPz;
            if (isDifferentFot) globalParameters.kz.changedFot = isDifferentFot;
            if (isDifferentMim) globalParameters.kz.changedMim = isDifferentMim;
            if (isDifferentMtr) globalParameters.kz.changedMtr = isDifferentMtr;
          }
          if (type === 'index') {
            if (isDifferentPz) globalParameters.index.changedPz = isDifferentPz;
            if (isDifferentFot)
              globalParameters.index.changedFot = isDifferentFot;
            if (isDifferentMim)
              globalParameters.index.changedMim = isDifferentMim;
            if (isDifferentMtr)
              globalParameters.index.changedMtr = isDifferentMtr;
          }

          if (
            isDifferentPz ||
            isDifferentFot ||
            isDifferentMim ||
            isDifferentMtr
          ) {
            findAndReplaceNodeInTreeArray(arr, data.id ?? data.rowID, {
              changedPz: isDifferentPz,
              changedFot: isDifferentFot,
              changedMim: isDifferentMim,
              changedMtr: isDifferentMtr
            } as any);
            map.set(data.id ?? data.rowID, {
              ...data,
              pz: data?.fotAsString?.replace('.', ',') || '',
              fot: data?.fotAsString?.replace('.', ',') || '',
              mim: data?.fotAsString?.replace('.', ',') || '',
              mtr: data?.fotAsString?.replace('.', ',') || '',

              changedPz: isDifferentPz,
              changedFot: isDifferentFot,
              changedMim: isDifferentMim,
              changedMtr: isDifferentMtr
            });

            let currentParent: ICoefficientData | null = parent;
            while (currentParent) {
              findAndReplaceNodeInTreeArray(
                arr,
                currentParent.id ?? currentParent.rowID,
                {
                  changedPz: isDifferentPz,
                  changedFot: isDifferentFot,
                  changedMim: isDifferentMim,
                  changedMtr: isDifferentMtr
                } as any
              );
              if (
                currentParent.parentID !== undefined &&
                currentParent.parentID !== null
              ) {
                currentParent = findParent(currentParent.parentID, map)!;
              } else {
                currentParent = null;
              }
            }
          }
        }
        for (const child of data.children) {
          difference(child, map, arr, type);
        }
      }

      function transaction(
        arr: ICoefficientData[],
        map: Map<number, ICoefficientData>,
        parentID: number | null,
        key: 'index' | 'kz'
      ) {
        const copyArr = [...arr];
        return copyArr.map((item) => {
          const copyItem = {
            ...item,
            parentID,
            pz: item?.pzAsString?.replace('.', ',') || '',
            fot: item?.fotAsString?.replace('.', ',') || '',
            mim: item?.mimAsString?.replace('.', ',') || '',
            mtr: item?.mtrAsString?.replace('.', ',') || '',
            changedPz:
              resp[key].global === null
                ? false
                : item.rowType !== 'RATE_POSITION' && item.rowType !== 'RATE'
                  ? resp[key].global?.pz !== item?.pz || false
                  : false,
            changedFot:
              resp[key].global === null
                ? false
                : item.rowType !== 'RATE_POSITION' && item.rowType !== 'RATE'
                  ? resp[key].global?.fot !== item?.fot || false
                  : false,
            changedMim:
              resp[key].global === null
                ? false
                : item.rowType !== 'RATE_POSITION' && item.rowType !== 'RATE'
                  ? resp[key].global?.mim !== item?.mim || false
                  : false,
            changedMtr:
              resp[key].global === null
                ? false
                : item.rowType !== 'RATE_POSITION' && item.rowType !== 'RATE'
                  ? resp[key].global?.mtr !== item?.mtr || false
                  : false
          };
          copyItem.children = transaction(
            copyItem.children,
            map,
            copyItem.id ?? copyItem.rowID,
            key
          );
          map.set(copyItem.id ?? copyItem.rowID, copyItem);
          // if (copyItem.parentID !== null) {
          // console.log(difference(copyItem, mapParents));
          // }
          return copyItem;
        });
      }

      const coeffIndex = transaction(
        resp.index.coefficients,
        mapParentsIndex.current,
        null,
        'index'
      );
      const coeffKz = transaction(
        resp.kz.coefficients,
        mapParentsKz.current,
        null,
        'kz'
      );
      coeffKz.forEach((item, idx, arr) => {
        difference(item, mapParentsKz.current, arr, 'kz');
      });
      coeffIndex.forEach((item, idx, arr) => {
        difference(item, mapParentsIndex.current, arr, 'index');
      });
      console.log(mapParentsKz.current, actList);

      let acts: { [key: string]: any } | undefined;
      if (!setupActs && actList?.actList?.length) {
        console.log(actList);
        acts = {
          actual: generateActs(actList.actList),
          removed: []
        };
        console.log('setup', acts);
        setSetupActs(true);
      }
      return {
        index: {
          coefficients: coeffIndex,
          global: {
            ...initGlobalParams,
            ...globalParameters.index,
            ...resp?.index?.global,
            pz: resp?.index?.global?.pzAsString?.replace('.', ',') || '',
            fot: resp?.index?.global?.fotAsString?.replace('.', ',') || '',
            mim: resp?.index?.global?.mimAsString?.replace('.', ',') || '',
            mtr: resp?.index?.global?.mtrAsString?.replace('.', ',') || '',
            parentID: null
          }
        },
        kz: {
          coefficients: coeffKz,
          global: {
            ...initGlobalParams,
            ...globalParameters.kz,
            ...resp?.kz?.global,
            pz: resp?.kz?.global?.pzAsString?.replace('.', ',') || '',
            fot: resp?.kz?.global?.fotAsString?.replace('.', ',') || '',
            mim: resp?.kz?.global?.mimAsString?.replace('.', ',') || '',
            mtr: resp?.kz?.global?.mtrAsString?.replace('.', ',') || '',
            parentID: null
          }
        },
        ...(isActs && {
          ...(acts ? ({ acts } as any) : {}),
          synchronized:
            calculationParameters &&
            'synchronized' in calculationParameters &&
            (calculationParameters?.synchronized! ?? null)
        })
      };
    },
    [actList?.actList, calculationParameters, isActs, setupActs]
  );

  useEffect(() => {
    if (
      ((isActs && coeffActData && actList?.actList?.length) ||
        (!isActs && coeffData)) &&
      calculationParameters
    ) {
      setCoefficientsData((prevState) => {
        console.log('set', actList?.actList);
        return Object.assign(
          prevState,
          modifyResponse(calculationParameters as any)
        );
      });
    }
  }, [
    calculationParameters,
    actList?.actList,
    isActs,
    coeffActData,
    coeffData
  ]);

  useEffect(() => {
    if (!open) {
      setCoefficientsData({
        ...defaultValues,
        acts: {
          actual: [],
          removed: []
        }
      });
      setSetupActs(false);

      // if (!currentAct && actList?.actList.length) {
      //   console.log('reset');
      //   changeAct(actList.actList[0]);
      // }
    }
  }, [open]);

  return (
    <Dialog PaperProps={popperSetup} open={open}>
      {/* <FormProvider {...methods}> */}
      <Formik<IForm>
        enableReinitialize={false}
        initialValues={coefficientsData}
        validateOnChange={true}
        onSubmit={(values) => {
          const submitData: IUpdateData[] = [];
          const errors: {
            [key: string]: {
              er: {
                start: null | ReactElement;
                end: null | ReactElement;
                on: null | ReactElement;
                split: null | ReactElement;
              };
            };
          } = {};
          values.acts?.actual?.forEach((labels, i, targetArr) => {
            labels.fields.forEach((act, idx) => {
              const er = checkDates(act, targetArr);

              const key = `За ${new Date(act.onDate || '')?.getFullYear()} год, строка ${idx + 1}`;
              if (er?.end || er?.on || er?.split || er?.start) {
                if (errors[key]) {
                  errors[key] = { ...errors[key], er };
                } else {
                  errors[key] = { er };
                }
              }
            });
          });
          if (
            ((isActs && !values.synchronized) || !isActs) &&
            values.index.global
          ) {
            submitData.push(modifyDataSubmit(values.index.global, 'INDEX'));
          }
          if (
            ((isActs && !values.synchronized) || !isActs) &&
            values.kz.global
          ) {
            submitData.push(modifyDataSubmit(values.kz.global, 'KZ'));
          }
          ((isActs && !values.synchronized) || !isActs) &&
            flatChildren(values.index.coefficients).forEach((item) =>
              submitData.push(modifyDataSubmit(item, 'INDEX'))
            );
          ((isActs && !values.synchronized) || !isActs) &&
            flatChildren(values.kz.coefficients).forEach((item) =>
              submitData.push(modifyDataSubmit(item, 'KZ'))
            );
          console.group('submit data');
          console.log('index coefficients>>>', values.index.coefficients);
          console.log('kz coefficients>>>', values.kz.coefficients);
          console.log('index global>>>', values.index.global);
          console.log('kz global>>>', values.kz.global);
          console.log('submitData>>>', submitData);
          console.groupEnd();
          console.log(submitData, values);
          const keysErrors = Object.keys(errors);
          console.log('errors>>>', keysErrors, errors);
          if (isActs && keysErrors.length) {
            enqueueSnackbar(
              <Box display={'flex'} flexDirection={'column'} gap={0.05}>
                Ошибка в Актах по периодам:
                {keysErrors.map((label) => {
                  const err = errors[label].er;
                  return (
                    <Box key={label} display={'flex'} flexDirection={'column'}>
                      <p>{label}</p>
                      <Box display={'flex'} gap={0.2} flexDirection={'column'}>
                        {err?.start}
                        {err?.end}
                        {err?.on}
                        {err?.split}
                      </Box>
                    </Box>
                  );
                })}
              </Box>,
              {
                variant: 'error',
                autoHideDuration: 3000
              }
            );

            return;
          }
          if (isActs) {
            if (currentAct) {
              console.log(values.acts?.actual, values.synchronized);
              Promise.all([
                values.acts?.removed.flatMap((act) =>
                  deleteAct({ calcID, actID: act.id })
                ),
                updateAct({
                  calcID,
                  values: (values.acts?.actual || []).flatMap((label) => {
                    // _.groups.flatMap(() => {
                    return label.fields.flatMap((act) => {
                      const { id, startDate, endDate } = act;
                      if (currentAct?.id === id) {
                        changeAct?.({
                          id: currentAct.id,
                          endDate: toLocalString(endDate as Date),
                          startDate: toLocalString(startDate as Date),
                          onDate: currentAct.onDate
                        });
                      }
                      return {
                        id,
                        endDate: toLocalString(endDate as Date),
                        startDate: toLocalString(startDate as Date)
                      };
                    });
                    // }),
                  })
                }),
                isActs &&
                  !values.synchronized &&
                  updateActCalculation({
                    calcID,
                    actID: currentAct.id,
                    coefficients: submitData
                  }),
                isActs &&
                  values.synchronized &&
                  syncAct({
                    calcID,
                    actID: currentAct.id,
                    projectType: 'handbk'
                  })
              ]).then(() => {
                close();
              });
              return;
            } else {
              Promise.all([
                values.acts?.removed.flatMap((act) =>
                  deleteAct({ calcID, actID: act.id })
                ),
                updateAct({
                  calcID,
                  values: (values.acts?.actual || []).flatMap((label) => {
                    // _.groups.flatMap(() => {
                    return label.fields.flatMap((act) => {
                      const { id, startDate, endDate } = act;
                      return {
                        id,
                        endDate: toLocalString(endDate as Date),
                        startDate: toLocalString(startDate as Date)
                      };
                    });
                    // }),
                  })
                })
              ]).then(() => {
                close();
              });
            }
          } else {
            updateCalculation({ calcID, coefficients: submitData }).then(() =>
              close()
            );
          }
        }}>
        {({ values }) => (
          <Form
            selectedTab={selectedTab}
            values={values}
            calculation={calculation}
            closeForm={close}
            key={'kz'}
            mapIndex={mapParentsIndex.current}
            mapKz={mapParentsKz.current}
            isActs={isActs}
          />
        )}
      </Formik>
    </Dialog>
  );
};

export default Parameters;
