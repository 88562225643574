import { useCreateLevelMutation } from 'api/calculations';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import { useSnackbar } from 'notistack';
import { getSlicedChildrenIds } from './hooks.service';
import { CreateLevelPayload } from './hooks.types';

export const useCreateLevel = () => {
  const [createLevelMutation, createLevelResponse] = useCreateLevelMutation();
  const { enqueueSnackbar } = useSnackbar();

  useMutationHandlers(
    createLevelResponse,
    () => {
      enqueueSnackbar('Уровень создан', {
        variant: 'success',
      });
    },
    () => {
      enqueueSnackbar('Ошибка при создании уровня', {
        variant: 'error',
      });
    },
  );

  const createLevel = ({
    rowLevel,
    calcID,
    childrenIds,
    groupIds,
    title,
    rowType,
    level,
    rowID,
    levelIds,
  }: CreateLevelPayload) => {
    const mergedIds = [...childrenIds, ...groupIds];
    const index = mergedIds.findIndex((id) => id === rowID);

    const groupChildren = getSlicedChildrenIds(mergedIds, index, true);
    const children = getSlicedChildrenIds(
      mergedIds,
      index,
      rowType === 'folded',
    );

    // if (index === -1) return;
    // console.log(children,groupChildren,mergedIds);
    console.log(
      rowType,
      index,
      level,
      levelIds,
      rowType === 'folded' &&
        index === 0 &&
        level > 1 &&
        levelIds[level - 1] !== null,
    );
    createLevelMutation({
      params: { calcID },
      body: {
        title: title || '-',
        // children,
        // groupChildren,
        children:
          level !== 1 && rowType === 'group'
            ? rowLevel === level
              ? []
              : children
            : children,
        groupChildren:
          level !== 1 && rowType === 'group'
            ? rowLevel === level
              ? []
              : groupChildren
            : groupChildren,
        parentID:
          level === 1
            ? undefined
            : levelIds[level - 1]
              ? levelIds[level - 1]
              : levelIds[level],
        level:
          (rowType === 'folded' &&
            (levelIds[level - 1] === null ||
              levelIds[level - 1] === undefined)) ||
          level === 0
            ? 1
            : level,
        recursiveLevelIncrease: level !== 1,
      },
    });
  };

  return { createLevel };
};
