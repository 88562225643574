/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import ClearIcon from '@mui/icons-material/Clear';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box } from '@mui/material';
import {
  ICoefficientData,
  OrNull
} from 'api/references/estimates/estimates.types';
import { ToggleButton } from 'components/ToggleButton';
import { ToggleButtonGroup } from 'components/ToggleButtonGroup';
import { Field, FieldProps, useFormikContext } from 'formik';
import { PopperComponent } from 'pages/Administration/AdminReferences/Prices/useTableData';
import Cell from 'pages/Calculations/components/Accomplishment/components/Cell';
import { StyledClearableTextField } from 'pages/Calculations/components/CalculationBasic/components/ParametersDialog/components/TabComponent/TabComponent.styles';
import React from 'react';
import { NumericFormat } from 'react-number-format';
import {
  clearData,
  findAndReplaceNodeInTreeArray,
  findNodeByRowID,
  getBaseName,
  getDefaultNode,
  getInputProps,
  operation,
  toValue,
  validate
} from '../../../helper';
import { InformIcon } from '../../InformIcon/InformIcon';
import { IForm } from '../../Parameters';
import { Badge } from '../../Parameters.style';
import { ListRow } from './ListRow';

export const Row = ({
  row,
  name,
  modifyKey,
  mapKz,
  mapIndex
}: {
  row: ICoefficientData;
  mapKz: any;
  mapIndex: any;
  name: string;
  modifyKey: 'kz' | 'index';
}) => {
  const [show, setShow] = React.useState(false);
  const { setFieldValue, values, getFieldProps } = useFormikContext<IForm>();
  // const { control, watch, setValue } = useFormContext<IForm>();

  const refPz = React.useRef<OrNull<HTMLElement>>(null);
  const refFot = React.useRef<OrNull<HTMLElement>>(null);
  const refMim = React.useRef<OrNull<HTMLElement>>(null);
  const refMtr = React.useRef<OrNull<HTMLElement>>(null);

  const disabledPZ = !!row?.fot || !!row?.mim || !!row?.mtr;
  const disabledRest = !!row?.pz;
  const getSuffix = React.useCallback(
    (name: string) => {
      return getFieldProps(name).value.isPercent ? '%' : undefined;
    },
    [getFieldProps]
  );
  const isPercent = React.useMemo(() => {
    return getFieldProps(`${modifyKey}.coefficients` + name).value?.isPercent;
  }, [getFieldProps, name]);

  const suffix = React.useMemo(() => {
    return isPercent ? '%' : undefined;
  }, [isPercent]);

  function findNodeByRowIDInForests(
    forests: ICoefficientData[],
    rowID: number
  ): ICoefficientData | null {
    let foundNode: ICoefficientData | null = null;

    forests.some((tree) => {
      foundNode = findNodeByRowID(tree, rowID);
      return foundNode !== null;
    });

    return foundNode;
  }

  const recursiveCheck = (item: ICoefficientData) => {
    // console.group('recursiveCheck')
    const actualMap = modifyKey === 'kz' ? mapKz : mapIndex;
    const changedArr: ICoefficientData[] = [];
    const global = values[modifyKey].global;
    const parent = item.parentID
      ? findNodeByRowIDInForests(
          values?.[modifyKey]?.coefficients,
          item.parentID
        )
      : global;
    console.group('parent checks');
    console.log('parent>>>', parent);
    console.log('item>>>', item);
    console.groupEnd();
    const different =
      parent!.pz !== item.pz ||
      parent!.fot !== item.fot ||
      parent!.mim !== item.mim ||
      parent!.mtr !== item.mtr;
    let temp: any = actualMap.get(item.parentID);
    // console.log(actualMap);
    // console.log(temp);
    // console.log(item);
    while (temp) {
      const copyTemp = { ...temp };
      // console.log('check');
      copyTemp.changedPz = different;
      copyTemp.changedMim = different;
      copyTemp.changedFot = different;
      copyTemp.changedMtr = different;
      changedArr.push(copyTemp);
      temp = actualMap.get(temp.parentID);
      // console.log(copyTemp)
    }
    // console.log(temp)
    return changedArr;
    // console.groupEnd()
    // const getParent = (id:number,parentID:number|null)=>{
    //   if (actualMap.has(id)) {
    //     const current = actualMap.get(id);
    //     if (!parent || !actualMap.has(parentID)) {
    //     }
    //     const parent = actualMap.get(id);
    //   }
    //
    // }
  };
  const processCoefficientData = React.useCallback(
    (
      data: ICoefficientData[],
      record: { [key: string]: any },
      id: number,
      clearKey: string | string[]
    ): ICoefficientData[] => {
      const newData: ICoefficientData[] = JSON.parse(JSON.stringify(data));
      const find = findNodeByRowIDInForests(newData, id);
      const findParent = find?.parentID
        ? findNodeByRowIDInForests(newData, find?.parentID)
        : null;
      const global = values[modifyKey].global;
      console.log(global);
      if (find) {
        Object.assign(find, record);
        // if (findParent) {
        console.log(find, global, findParent);
        find.changedPz = findParent
          ? toValue(findParent?.pz as null) !== toValue(find.pz) || false
          : toValue(global.pz) !== toValue(find.pz);
        find.changedFot = findParent
          ? toValue(findParent?.fot as null) !== toValue(find.fot) || false
          : toValue(global.fot) !== toValue(find.fot);
        find.changedMim = findParent
          ? toValue(findParent?.mim as null) !== toValue(find.mim) || false
          : toValue(global.mim) !== toValue(find.mim);
        find.changedMtr = findParent
          ? toValue(findParent?.mtr as null) !== toValue(find.mtr) || false
          : toValue(global.mtr) !== toValue(find.mtr);

        find.children = (find.children || []).map((item) =>
          traverse(item, findParent)
        );
        find.parentID && clearParent(find.id ?? find.rowID, newData);
      }

      function clearParent(id: number, arrData: ICoefficientData[]) {
        const finded = findNodeByRowIDInForests(arrData, id);
        let dataObjects: ICoefficientData[] = [];
        console.log(finded);
        if (finded) {
          dataObjects = recursiveCheck(finded).sort((itemA, itemB) =>
            itemA!.lvl! > itemB!.lvl! ? 1 : -1
          );
        }

        console.log(dataObjects);
        let isDiff = true;
        for (const f of dataObjects) {
          console.log(global, f);
          if (
            global.pz != f.pz ||
            find!.pz != f.pz ||
            global.fot != f.fot ||
            find!.fot != f.fot ||
            global.mim != f.mim ||
            find!.mim != f.mim ||
            global.mtr != f.mtr ||
            find!.mtr != f.mtr
          ) {
            console.log('not equal');
            isDiff = true;
            f.changedPz = true;
            f.changedFot = true;
            f.changedMim = true;
            f.changedMtr = true;
          } else {
            console.log('equal');
            isDiff = false;
            f.changedPz = false;
            f.changedFot = false;
            f.changedMim = false;
            f.changedMtr = false;
          }
          const {
            children,
            mim,
            mtr,
            fot,
            lvl,
            rowType,
            title,
            parentID,
            type,
            pz,
            id,
            rowID,
            ...folderWithoutChildren
          } = f;
          findAndReplaceNodeInTreeArray(
            arrData,
            f.id ?? f.rowID,
            folderWithoutChildren as any
          );
        }
      }

      function traverse(
        node: ICoefficientData,
        parent: ICoefficientData | null
      ): ICoefficientData {
        console.group('traverse');
        console.log(global);
        console.log(record);
        console.log(node);
        console.log(
          (record?.changedPz && !!node.pz) ||
            ((!!record?.fot || !!record?.mim || !!record?.mtr) && !!node.pz)
        );
        console.groupEnd();
        const copyNode = {
          ...node,
          // @ts-ignore
          ...record,
          changedPz: parent
            ? toValue(parent.pz) !== toValue(node.pz) || false
            : false,
          changedFot: parent
            ? toValue(parent.fot) !== toValue(node.fot) || false
            : false,
          changedMim: parent
            ? toValue(parent.mim) !== toValue(node.mim) || false
            : false,
          changedMtr: parent
            ? toValue(parent.mtr) !== toValue(node.mtr) || false
            : false
        };

        if (copyNode.children && copyNode.children.length > 0) {
          copyNode.children = copyNode.children.map((item) =>
            traverse(item, copyNode)
          );
        }

        return copyNode;
      }

      console.log(newData);
      return newData;
    },
    [values]
  );

  const getHelpIcon = React.useCallback(() => {
    const node = getFieldProps(`${modifyKey}.coefficients` + name).value;
    const condition =
      node.changedPz || node.changedFot || node.changedMim || node.changedMtr;
    return condition && node?.rowType === 'FOLDER' ? <InformIcon /> : null;
  }, [name, row]);

  return (
    <React.Fragment>
      <Box
        paddingLeft={`${row.lvl! > 1 ? 12 * (row.lvl! - 1) : 0}px`}
        gap={'4px'}
        display={'flex'}
        alignItems={'center'}>
        <Box display={'flex'} alignItems={'center'} gap={'4px'}>
          {!!row.children?.length && row.rowType === 'FOLDER' ? (
            <KeyboardArrowDownIcon
              style={{ cursor: 'pointer', rotate: show ? '180deg' : '0deg' }}
              onClick={() => setShow((prevState) => !prevState)}
            />
          ) : (
            <div style={{ width: '24px' }} />
          )}
          {row.rowType! === 'FOLDER' ? (
            <Badge>УР {row.lvl}</Badge>
          ) : (
            <div>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M15.5556 4H5.77778C4.8 4 4 4.8 4 5.77778V18.2222C4 19.2 4.8 20 5.77778 20H18.2222C19.2 20 20 19.2 20 18.2222V8.44444L15.5556 4ZM7.55556 7.55556H12V9.33333H7.55556V7.55556ZM16.4444 16.4444H7.55556V14.6667H16.4444V16.4444ZM16.4444 12.8889H7.55556V11.1111H16.4444V12.8889ZM14.6667 9.33333V5.77778L18.2222 9.33333H14.6667Z"
                  fill="#7890B2"
                />
              </svg>
            </div>
          )}
        </Box>
        <Cell>{row.title}</Cell>
      </Box>
      <Field
        shouldUpdate={(nextProps: any, prevProps: any) =>
          nextProps.value !== prevProps.value
        }
        // control={control}
        name={(`${modifyKey}.coefficients` + name + '.isPercent') as any}
        render={({ field }: FieldProps) => {
          return (
            <ToggleButtonGroup
              {...field}
              exclusive
              fullWidth
              onChange={(_, value) => {
                const node: ICoefficientData = getFieldProps(
                  getBaseName(field.name)
                ).value;
                if (
                  (node.isPercent && value === '%') ||
                  (!node.isPercent && value === 'К')
                )
                  return;
                const coefficients = [...values[modifyKey].coefficients];

                const newNode: ICoefficientData = {
                  ...node,
                  pz: operation(node.pz, value),
                  fot: operation(node.fot, value),
                  mim: operation(node.mim, value),
                  mtr: operation(node.mtr, value),
                  isPercent: value === '%'
                };
                // setFieldValue(field.name, value === '%');
                findAndReplaceNodeInTreeArray(
                  coefficients,
                  node.rowID,
                  newNode
                );
                setFieldValue(`${modifyKey}.coefficients`, coefficients);
              }}>
              {['К', '%'].map((groupButton) => {
                // const selected = groupButton === '%' && row.isPercent;
                const selected =
                  groupButton === 'К' ? !field.value : field.value;
                return (
                  <ToggleButton
                    key={groupButton}
                    value={groupButton}
                    selected={selected}>
                    {groupButton}
                  </ToggleButton>
                );
              })}
            </ToggleButtonGroup>
          );
        }}
      />
      <Field
        // shouldUpdate={(nextProps: any, prevProps: any) => nextProps.value !== prevProps.value}
        // control={control}
        name={(`${modifyKey}.coefficients` + name + '.pz') as any}
        validateOnChange={true}
        validate={validate}
        render={({ field, form }: FieldProps) => {
          const node = getFieldProps(getBaseName(field.name)).value;
          const message = (form as any).errors?.[modifyKey]?.coefficients?.find(
            (item: any) => item?.pz
          )?.pz;
          const isEmpty =
            form.initialValues?.[modifyKey]?.coefficients?.[
              Number(name.replace('.', ''))
            ]?.pz;
          const global = getFieldProps(`${modifyKey}.global`).value;
          return (
            <>
              <NumericFormat
                {...field}
                onChange={(e) => {
                  const newCoefficients = processCoefficientData(
                    values[modifyKey].coefficients!,
                    { ...getDefaultNode(node), pz: e.target.value },
                    // { ...getDefaultNode(node), pz: e.target.value, changedPz: false },
                    node.rowID!,
                    ['pz', 'fot', 'mim', 'mtr']
                  );
                  setFieldValue(`${modifyKey}.coefficients`, newCoefficients);
                  form.validateForm(field.name);
                }}
                InputProps={{
                  ...getInputProps(
                    disabledPZ,
                    node.changedPz && !field.value,
                    isEmpty
                  ),
                  ref: refPz
                }}
                customInput={StyledClearableTextField}
                // isAllowed={(values) => {
                //   const { formattedValue } = values;
                //   return /^(\d{0,20})(?:,\d{0,6})?%?$/.test(formattedValue);
                // }}
                placeholder={isPercent ? '%' : 'К'}
                allowNegative={false}
                thousandSeparator={' '}
                decimalSeparator=","
                decimalScale={15}
                suffix={getSuffix(getBaseName(field.name))}
                onClear={() => setFieldValue(field.name, '')}
              />
              <PopperComponent message={message} refItem={refPz.current} />
            </>
          );
        }}
      />
      <Field
        // shouldUpdate={(nextProps: any, prevProps: any) => nextProps.value !== prevProps.value}
        // control={control}
        name={(`${modifyKey}.coefficients` + name + '.fot') as any}
        validateOnChange={true}
        validate={validate}
        render={({ field, form }: FieldProps) => {
          const node = getFieldProps(getBaseName(field.name)).value;
          const message = (form as any).errors?.[modifyKey]?.coefficients?.[0]
            ?.fot;
          const isEmpty =
            form.initialValues[modifyKey]?.coefficients?.[
              Number(name.replace('.', ''))
            ]?.fot;
          const global = getFieldProps(`${modifyKey}.global`).value;
          return (
            <>
              <NumericFormat
                {...field}
                // inputRef={ref}
                onChange={(e) => {
                  const newCoefficients = processCoefficientData(
                    values[modifyKey].coefficients!,
                    { pz: '', fot: e.target.value },
                    // { pz:'',fot: e.target.value, changedFot: false,changedPz: global.changedPz },
                    node.rowID!,
                    ['fot', 'pz']
                  );
                  setFieldValue(`${modifyKey}.coefficients`, newCoefficients);
                  form.validateField(field.name);
                }}
                InputProps={{
                  ...getInputProps(
                    disabledRest,
                    node.changedFot && !field.value,
                    isEmpty
                  ),
                  ref: refFot
                }}
                customInput={StyledClearableTextField}
                // isAllowed={(values) => {
                //   const { formattedValue } = values;
                //   return /^(\d{0,20})(?:,\d{0,6})?%?$/.test(formattedValue);
                //   // return floatValue < MAX_LIMIT;
                // }}
                allowNegative={false}
                thousandSeparator={' '}
                placeholder={isPercent ? '%' : 'К'}
                // suffix={isPercent ? '%' : ''}
                decimalSeparator=","
                decimalScale={15}
                suffix={getSuffix(getBaseName(field.name))}
                // error={!!fieldState.error}
                onClear={() => setFieldValue(field.name, '')}
              />
              <PopperComponent message={message} refItem={refFot.current} />
            </>
          );
        }}
      />
      <Field
        // shouldUpdate={(nextProps: any, prevProps: any) => nextProps.value !== prevProps.value}
        // control={control}
        name={(`${modifyKey}.coefficients` + name + '.mim') as any}
        validateOnChange={true}
        validate={validate}
        render={({ field, form }: FieldProps) => {
          const node = getFieldProps(getBaseName(field.name)).value;
          const message = (form as any).errors?.[modifyKey]?.coefficients?.[0]
            ?.mim;
          const isEmpty =
            form.initialValues?.[modifyKey]?.coefficients?.[
              Number(name.replace('.', ''))
            ]?.mim;
          const global = getFieldProps(`${modifyKey}.global`).value;

          return (
            <>
              <NumericFormat
                {...field}
                // inputRef={ref}
                onChange={(e) => {
                  const newCoefficients = processCoefficientData(
                    values[modifyKey].coefficients!,
                    { pz: '', mim: e.target.value },
                    // {  pz:'',mim: e.target.value, changedMim: false,changedPz: global.changedPz },
                    node.rowID!,
                    ['mim', 'pz']
                  );
                  console.log(newCoefficients);
                  setFieldValue(`${modifyKey}.coefficients`, newCoefficients);
                  form.validateField(field.name);
                }}
                InputProps={{
                  ...getInputProps(
                    disabledRest,
                    node.changedMim && !field.value,
                    isEmpty
                  ),
                  ref: refMim
                }}
                customInput={StyledClearableTextField}
                // isAllowed={(values) => {
                //   const { formattedValue } = values;
                //   return /^(\d{0,20})(?:,\d{0,6})?%?$/.test(formattedValue);
                //   // return floatValue < MAX_LIMIT;
                // }}
                placeholder={isPercent ? '%' : 'К'}
                suffix={getSuffix(getBaseName(field.name))}
                // suffix={isPercent ? '%' : ''}
                allowNegative={false}
                thousandSeparator={' '}
                decimalSeparator=","
                decimalScale={15}
                // error={!!fieldState.error}
                onClear={() => setFieldValue(field.name, '')}
              />
              <PopperComponent message={message} refItem={refMim} />
            </>
          );
        }}
      />
      <Field
        // shouldUpdate={(nextProps: any, prevProps: any) => nextProps.value !== prevProps.value}
        // control={control}
        name={(`${modifyKey}.coefficients` + name + '.mtr') as any}
        validateOnChange={true}
        validate={validate}
        render={({ field, form }: FieldProps) => {
          const node = getFieldProps(getBaseName(field.name)).value;
          const message = (form as any).errors?.[modifyKey]?.coefficients?.[0]
            ?.mtr;
          const isEmpty =
            form.initialValues?.[modifyKey]?.coefficients?.[
              Number(name.replace('.', ''))
            ]?.mtr;
          const global = getFieldProps(`${modifyKey}.global`).value;
          return (
            <>
              <NumericFormat
                {...field}
                // inputRef={ref}
                onChange={(e) => {
                  const newCoefficients = processCoefficientData(
                    values[modifyKey].coefficients!,
                    { pz: '', mtr: e.target.value },
                    // {  pz:'',mtr: e.target.value, changedMtr: false,changedPz: global.changedPz },
                    node.rowID!,
                    ['mtr', 'pz']
                  );
                  setFieldValue(`${modifyKey}.coefficients`, newCoefficients);
                  form.validateField(field.name).finally(() => {
                    if (e.target.value) {
                      if (global.pz) {
                        // setFieldValue(`${modifyKey}.global.pz`, '');
                        setFieldValue(`${modifyKey}.global.changedPz`, true);
                      }
                      if (global.mtr) {
                        // setFieldValue(`${modifyKey}.global.mtr`, '');
                        setFieldValue(`${modifyKey}.global.changedMtr`, true);
                      }
                    }
                  });
                }}
                InputProps={{
                  ...getInputProps(
                    disabledRest,
                    node.changedMtr && !field.value,
                    isEmpty
                  ),
                  ref: refMtr
                }}
                customInput={StyledClearableTextField}
                // isAllowed={(values) => {
                //   const { formattedValue } = values;
                //   return /^(\d{0,20})(?:,\d{0,6})?%?$/.test(formattedValue);
                //   // return floatValue < MAX_LIMIT;
                // }}
                placeholder={isPercent ? '%' : 'К'}
                // suffix={isPercent ? '%' : ''}
                allowNegative={false}
                thousandSeparator={' '}
                decimalSeparator=","
                decimalScale={15}
                suffix={getSuffix(getBaseName(field.name))}
                // error={!!fieldState.error}
                onClear={() => setFieldValue(field.name, '')}
              />
              <PopperComponent message={message} refItem={refMtr.current} />
            </>
          );
        }}
      />

      <Box
        display={'flex'}
        position={'relative'}
        alignItems={'center'}
        justifyContent={'center'}>
        {getHelpIcon()}
        <ClearIcon
          sx={{ position: 'relative', right: '-15px' }}
          onClick={() => {
            console.log(`${modifyKey}.coefficients` + name);
            const update = {
              ...row,
              ...clearData
            };
            setFieldValue(`${modifyKey}.coefficients` + name, update);
          }}
          cursor={'pointer'}
          color={'secondary'}
        />
      </Box>
      {show && (
        <ListRow
          list={row.children}
          mapKz={mapKz}
          mapIndex={mapIndex}
          name={name + '.children'}
          modifyKey={modifyKey}
        />
      )}
    </React.Fragment>
  );
};
Row.displayName = 'Row';
