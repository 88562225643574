import { Stack } from '@mui/material';
import { api } from 'api/api';
import { useGetLSRFilesQuery } from 'api/lsr';
import { useWS } from 'api/web-socket';
import { wsContext } from 'contexts/ws';
import useBreadcrumbs from 'hooks/useBreadcrumbs';
import { useProjectId } from 'hooks/useProjectId';
import { FC, SyntheticEvent, useContext, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from 'store/store';
import { LSRTabValue, topbarTabsLabels } from 'types';
import { Table, Topbar } from './components';
import WSCustomSnackbar from './components/WSCustomSnackbar/WSCustomSnackbar';

export const Lsr: FC = () => {
  const {} = useWS();
  const dispatch = useAppDispatch();

  const projectID = useProjectId();

  const { pathname } = useLocation();

  const cWS = useContext(wsContext);

  const { data, isFetching } = useGetLSRFilesQuery({ projectID });

  const [selectedTab, setSelectedTab] = useState<LSRTabValue>('all');
  //const [isUploaded, setUploaded] = useState<UploadType>('start');

  const filteredLSRFilesData = useMemo(() => {
    if (!data) return [];
    if (selectedTab === 'all') return data.files;

    if (selectedTab === 'undefiend')
      return data.files.filter(
        (lsrFile) =>
          lsrFile.status === selectedTab || lsrFile.status === 'not_selected'
      );

    return data.files.filter((lsrFile) => lsrFile.status === selectedTab);
  }, [selectedTab, data]);

  const handleRefresh = () => {
    dispatch(api.util.invalidateTags(['LSR', 'ProjectDashboard']));
    // cWS.setLsrUploaded("start");
  };

  const changeTabHandler = (_: SyntheticEvent, newValue: LSRTabValue) => {
    setSelectedTab(newValue);
  };

  useBreadcrumbs(
    [{ title: `Реестр ЛСР / ${topbarTabsLabels[selectedTab]}`, url: pathname }],
    [selectedTab]
  );

  return (
    <Stack flex="1 1 auto" overflow="auto">
      <Topbar value={selectedTab} onChange={changeTabHandler} />
      <Table data={filteredLSRFilesData} isLoading={isFetching} />
      {cWS.socketLsr.isUploaded !== 'start' && !cWS.socketLsr.isDrawerOpen && (
        <WSCustomSnackbar
          isDrawerOpen={cWS.socketLsr.isDrawerOpen ?? false}
          isUploaded={cWS.socketLsr.isUploaded}
          projectIDEquals={cWS.socketLsr.projectIDInit == projectID}
          handleRefresh={handleRefresh}
        />
      )}
    </Stack>
  );
};
