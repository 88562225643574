import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import useWebSocket, {
  ReadyState,
  resetGlobalState
} from 'react-use-websocket';

import { useRefreshTokenMutation } from 'api/auth';
import { useMutationHandlers } from 'hooks/useMutationHandlers';
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types';
import { setCredentials } from 'store/slices/auth';
import { useAppDispatch, useTypedSelector } from 'store/store';
import {
  ConnectionStatusType,
  ProviderWSProps,
  SOCKET_URL_LSR,
  WSContextCalcProps,
  WSContextHandbookProps,
  WSContextLSRProps,
  WSContextProps,
  WSEventType,
  WSRequest,
  WSResponse,
  WSTaskStatus
} from '.';

export const WSContext = createContext<WSContextProps | null>(null);

export const WSLSRContext = createContext<WSContextLSRProps | null>(null);
export const WSHandbookContext = createContext<WSContextHandbookProps | null>(
  null
);
export const WSCalcContext = createContext<WSContextCalcProps | null>(null);

export const ProviderWS: React.FC<ProviderWSProps> = (props) => {
  const { token, refreshToken } = useTypedSelector((state) => state.auth);
  const [isAuth, setIsAuth] = useState<WSContextProps['isAuth']>(false);
  const dispatch = useAppDispatch();
  const [getRefreshToken, refreshTokenResponse] = useRefreshTokenMutation();

  const { lastMessage, readyState, sendJsonMessage } = useWebSocket(
    SOCKET_URL_LSR,
    {
      share: true,
      onOpen: () => {
        if (token && !isAuth) {
          sendSubscribeAuth(token);
        }
      },
      shouldReconnect: () => {
        return true;
      }
    }
  );

  const connectionStatus = useMemo(
    () =>
      ({
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
      })[readyState] as ConnectionStatusType,
    [readyState]
  );

  useEffect(() => {
    console.log('WS. connectionStatus:', connectionStatus);
  }, [connectionStatus]);

  const isOpen = readyState === ReadyState.OPEN;

  const sendSubscribeAuth = useCallback(
    (token: string) => {
      const data: WSRequest<WSEventType.auth> = {
        event: WSEventType.auth,
        body: { token: token }
      };
      sendJsonMessage({
        event: data.event,
        body: JSON.stringify(data.body)
      });
    },
    [sendJsonMessage]
  );

  useEffect(() => {
    return () => {
      resetGlobalState(SOCKET_URL_LSR);
    };
  }, []);

  useEffect(() => {
    if (lastMessage && lastMessage.data) {
      const data = JSON.parse(lastMessage.data);
      const result: WSResponse<typeof data.event, typeof data.status> = data;
      switch (result.event) {
        case WSEventType.auth: {
          switch (result.status) {
            case WSTaskStatus.OK: {
              setIsAuth(true);
              break;
            }
            case WSTaskStatus.ERROR: {
              setIsAuth(false);
              if (refreshToken) getRefreshToken({ refreshToken: refreshToken });
              break;
            }
            default:
              break;
          }
          break;
        }
        default:
          break;
      }
    }
  }, [getRefreshToken, lastMessage, refreshToken]);

  useMutationHandlers(refreshTokenResponse, (data) => {
    if (!isAuth) {
      dispatch(setCredentials(data));
      sendSubscribeAuth(data.token);
    }
  });

  const lsr = useLSR({ isOpen, sendJsonMessage, token, lastMessage });
  const handbook = useHandbook({ isOpen, sendJsonMessage, token, lastMessage });
  const calc = useCalc({ isOpen, sendJsonMessage, token, lastMessage });

  return (
    <WSContext.Provider
      value={{
        isAuth,
        isOpen,
        connectionStatus,
        readyState
      }}>
      <WSLSRContext.Provider value={lsr}>
        <WSHandbookContext.Provider value={handbook}>
          <WSCalcContext.Provider value={calc}>
            {props.children}
          </WSCalcContext.Provider>
        </WSHandbookContext.Provider>
      </WSLSRContext.Provider>
    </WSContext.Provider>
  );
};

const useLSR = (props: {
  isOpen: boolean;
  sendJsonMessage: SendJsonMessage;
  token: any;
  lastMessage: MessageEvent<any> | null;
}): WSContextLSRProps => {
  const [uploadFileLsrProgress, setUploadFileLsrProgress] =
    useState<WSContextLSRProps['uploadFileLsrProgress']>();
  const [uploadFileLsrStatus, setUploadFileLsrStatus] =
    useState<WSContextLSRProps['uploadFileLsrStatus']>();
  const [uploadFileLsrCanceled, setUploadFileLsrCanceled] =
    useState<WSContextLSRProps['uploadFileLsrCanceled']>(false);
  const [uploadFileLsrError, setUploadFileLsrError] =
    useState<WSContextLSRProps['uploadFileLsrError']>(null);

  const uploadFileLsr: WSContextLSRProps['uploadFileLsr'] = useCallback(
    (params) => {
      const data: WSRequest<WSEventType.lsr_upload> = {
        event: WSEventType.lsr_upload,
        body: params
      };
      if (props.token && props.isOpen) {
        props.sendJsonMessage({
          event: data.event,
          body: JSON.stringify(data.body)
        });
      }
    },
    [props]
  );

  const clearUploadFileLsrProgress = useCallback(
    () => setUploadFileLsrProgress(undefined),
    []
  );
  const clearUploadFileLsrStatus = useCallback(
    () => setUploadFileLsrStatus(undefined),
    []
  );
  const clearUploadFileLsrCanceled = useCallback(
    () => setUploadFileLsrCanceled(false),
    []
  );
  const clearUploadFileLsrError = useCallback(
    () => setUploadFileLsrError(null),
    []
  );

  const clearAll = useCallback(() => {
    setUploadFileLsrError(null);
    setUploadFileLsrCanceled(false);
    setUploadFileLsrStatus(undefined);
    setUploadFileLsrProgress(undefined);
  }, []);

  useEffect(() => {
    if (props.lastMessage && props.lastMessage.data) {
      const data = JSON.parse(props.lastMessage.data);
      const result: WSResponse<typeof data.event, typeof data.status> = data;

      switch (result.event) {
        case WSEventType.lsr_upload: {
          switch (result.status) {
            case WSTaskStatus.CANCELED: {
              setUploadFileLsrCanceled(true);
              break;
            }
            case WSTaskStatus.ERROR: {
              const finalResult: WSResponse<
                WSEventType.lsr_upload,
                WSTaskStatus.ERROR
              > = result;
              setUploadFileLsrError(finalResult.body);
              break;
            }
            case WSTaskStatus.IN_PROGRESS: {
              const finalResult: WSResponse<
                WSEventType.lsr_upload,
                WSTaskStatus.IN_PROGRESS
              > = result;
              setUploadFileLsrProgress(finalResult.body);
              break;
            }
            case WSTaskStatus.OK: {
              const finalResult: WSResponse<
                WSEventType.lsr_upload,
                WSTaskStatus.OK
              > = result;
              setUploadFileLsrStatus(finalResult.body);

              break;
            }
            default:
              break;
          }
          break;
        }
        default:
          break;
      }
    }
  }, [props.lastMessage]);

  return {
    uploadFileLsrProgress,
    uploadFileLsrStatus,
    uploadFileLsrCanceled,
    uploadFileLsrError,
    uploadFileLsr,
    clearUploadFileLsrProgress,
    clearUploadFileLsrStatus,
    clearUploadFileLsrCanceled,
    clearUploadFileLsrError,
    clearAll
  };
};

const useHandbook = (props: {
  isOpen: boolean;
  sendJsonMessage: SendJsonMessage;
  token: any;
  lastMessage: MessageEvent<any> | null;
}): WSContextHandbookProps => {
  const [uploadFileHandbookProgress, setUploadFileHandbookProgress] =
    useState<WSContextHandbookProps['uploadFileHandbookProgress']>();
  const [uploadFileHandbookStatus, setUploadFileHandbookStatus] =
    useState<WSContextHandbookProps['uploadFileHandbookStatus']>();
  const [uploadFileHandbookCanceled, setUploadFileHandbookCanceled] =
    useState<WSContextHandbookProps['uploadFileHandbookCanceled']>(false);
  const [uploadFileHandbookError, setUploadFileHandbookError] =
    useState<WSContextHandbookProps['uploadFileHandbookError']>(null);

  const uploadFileHandbook: WSContextHandbookProps['uploadFileHandbook'] =
    useCallback(
      (params) => {
        const data: WSRequest<WSEventType.handbk_upload> = {
          event: WSEventType.handbk_upload,
          body: params
        };
        if (props.token && props.isOpen) {
          props.sendJsonMessage({
            event: data.event,
            body: JSON.stringify(data.body)
          });
        }
      },
      [props]
    );
  const clearUploadFileHandbookProgress = useCallback(
    () => setUploadFileHandbookProgress(undefined),
    []
  );
  const clearUploadFileHandbookStatus = useCallback(
    () => setUploadFileHandbookStatus(undefined),
    []
  );
  const clearUploadFileHandbookCanceled = useCallback(
    () => setUploadFileHandbookCanceled(false),
    []
  );
  const clearUploadFileHandbookError = useCallback(
    () => setUploadFileHandbookError(null),
    []
  );
  const clearAll = useCallback(() => {
    setUploadFileHandbookProgress(undefined);
    setUploadFileHandbookStatus(undefined);
    setUploadFileHandbookCanceled(false);
    setUploadFileHandbookError(null);
  }, []);

  useEffect(() => {
    if (props.lastMessage && props.lastMessage.data) {
      const data = JSON.parse(props.lastMessage.data);
      const result: WSResponse<typeof data.event, typeof data.status> = data;

      switch (result.event) {
        case WSEventType.handbk_upload: {
          switch (result.status) {
            case WSTaskStatus.CANCELED: {
              setUploadFileHandbookCanceled(true);
              break;
            }
            case WSTaskStatus.ERROR: {
              const finalResult: WSResponse<
                WSEventType.handbk_upload,
                WSTaskStatus.ERROR
              > = result;
              setUploadFileHandbookError(finalResult.body);
              break;
            }
            case WSTaskStatus.IN_PROGRESS: {
              const finalResult: WSResponse<
                WSEventType.handbk_upload,
                WSTaskStatus.IN_PROGRESS
              > = result;
              setUploadFileHandbookProgress(finalResult.body);
              break;
            }
            case WSTaskStatus.OK: {
              const finalResult: WSResponse<
                WSEventType.handbk_upload,
                WSTaskStatus.OK
              > = result;
              setUploadFileHandbookStatus(finalResult.body);

              break;
            }
            default:
              break;
          }
          break;
        }
        default:
          break;
      }
    }
  }, [props.lastMessage]);

  return {
    uploadFileHandbookProgress,
    uploadFileHandbookStatus,
    uploadFileHandbookCanceled,
    uploadFileHandbookError,
    uploadFileHandbook,
    clearUploadFileHandbookProgress,
    clearUploadFileHandbookStatus,
    clearUploadFileHandbookCanceled,
    clearUploadFileHandbookError,
    clearAll
  };
};

const useCalc = (props: {
  isOpen: boolean;
  sendJsonMessage: SendJsonMessage;
  token: any;
  lastMessage: MessageEvent<any> | null;
}): WSContextCalcProps => {
  const [createCalcProgress, setCreateCalcProgress] =
    useState<WSContextCalcProps['createCalcProgress']>();
  const [createCalcStatus, setCreateCalcStatus] =
    useState<WSContextCalcProps['createCalcStatus']>();
  const [createCalcCanceled, setCreateCalcCanceled] =
    useState<WSContextCalcProps['createCalcCanceled']>(false);
  const [createCalcError, setCreateCalcError] =
    useState<WSContextCalcProps['createCalcError']>(false);

  const createCalc: WSContextCalcProps['createCalc'] = useCallback(
    (params) => {
      const data: WSRequest<WSEventType.lsr_create_calc> = {
        event: WSEventType.lsr_create_calc,
        body: params
      };
      if (props.token && props.isOpen) {
        props.sendJsonMessage({
          event: data.event,
          body: JSON.stringify(data.body)
        });
      }
    },
    [props]
  );
  const clearCalcProgress = useCallback(
    () => setCreateCalcProgress(undefined),
    []
  );
  const clearCalcStatus = useCallback(() => setCreateCalcStatus(undefined), []);
  const clearCalcCanceled = useCallback(() => setCreateCalcCanceled(false), []);
  const clearCalcError = useCallback(() => setCreateCalcError(false), []);

  const clearAll = useCallback(() => {
    setCreateCalcProgress(undefined);
    setCreateCalcStatus(undefined);
    setCreateCalcCanceled(false);
    setCreateCalcError(false);
  }, []);

  useEffect(() => {
    if (props.lastMessage && props.lastMessage.data) {
      const data = JSON.parse(props.lastMessage.data);
      const result: WSResponse<typeof data.event, typeof data.status> = data;

      switch (result.event) {
        case WSEventType.lsr_create_calc: {
          switch (result.status) {
            case WSTaskStatus.ERROR: {
              setCreateCalcError(true);
              break;
            }
            case WSTaskStatus.CANCELED: {
              setCreateCalcCanceled(true);
              break;
            }
            case WSTaskStatus.IN_PROGRESS: {
              const finalResult: WSResponse<
                WSEventType.lsr_create_calc,
                WSTaskStatus.IN_PROGRESS
              > = result;
              setCreateCalcProgress(finalResult.body);
              break;
            }
            case WSTaskStatus.OK: {
              const finalResult: WSResponse<
                WSEventType.lsr_create_calc,
                WSTaskStatus.OK
              > = result;
              setCreateCalcStatus(finalResult.body);

              break;
            }
            default:
              break;
          }
          break;
        }
        default:
          break;
      }
    }
  }, [props.lastMessage]);

  return {
    createCalcProgress,
    createCalcStatus,
    createCalcCanceled,
    createCalcError,
    createCalc,
    clearCalcProgress,
    clearCalcStatus,
    clearCalcCanceled,
    clearCalcError,
    clearAll
  };
};
