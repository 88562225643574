/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import { useFormikContext } from 'formik';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { TTabType } from 'pages/Calculations/components/CalculationDirectory/handbook.services';
import React from 'react';
import { CalcData } from 'types';
import Header from '../Header';
import Menu from '../Menu';
import { IForm } from '../Parameters';
import { CoeffParameters, WrapperParameters } from '../Parameters.style';
import { TTab } from '../Parameters.types';
import { ActsTab } from './components/ActTable';
import { CoefficientsTable } from './components/CoefficientsTable';

export const Form = ({
  closeForm,
  selectedTab,
  mapKz,
  mapIndex,
  isActs,
  calculation,
  values
}: {
  values: IForm;
  closeForm: () => void;
  mapKz: any;
  mapIndex: any;
  isActs: boolean;
  calculation?: CalcData;
  selectedTab: TTabType | null;
}) => {
  const { dirty, initialValues, setFieldValue } = useFormikContext<IForm>();
  const [tab, setTab] = React.useState<TTab>(
    isActs ? 'acts' : 'indirect_costs'
  );

  const isOneChange = React.useRef(false);

  const changeTab = React.useCallback((str: TTab) => setTab(str), []);

  const { openConfirm, ConfirmDialog } = useConfirmDialog({
    handleConfirm: (confirm) => {
      if (confirm) {
        closeForm();
      }
    }
  });

  const handleCloseForm = React.useCallback(() => {
    if (dirty) {
      openConfirm();
    } else {
      closeForm();
    }
  }, [closeForm, dirty, openConfirm]);

  React.useEffect(() => {
    if (initialValues) {
      const syncDefault = initialValues.synchronized;

      if (syncDefault) {
        if (!isOneChange.current) {
          isOneChange.current = true;
          setFieldValue('synchronized', false, false);
        }
        if (!dirty) {
          isOneChange.current = false;
          setFieldValue('synchronized', syncDefault, false);
        }
      }
    }
  }, [dirty, initialValues, setFieldValue]);

  return (
    <WrapperParameters>
      {/* <WrapperParameters onSubmit={methods.handleSubmit(console.log)}> */}
      <Header
        close={handleCloseForm}
        isActs={isActs}
        selectedTab={selectedTab}
      />
      <Menu
        isActs={isActs}
        tab={tab}
        setTab={changeTab}
        selectedTab={selectedTab}
      />
      <CoeffParameters>
        {tab === 'acts' ? (
          <ActsTab isActs={isActs} calculation={calculation} values={values} />
        ) : (
          <CoefficientsTable
            closeForm={closeForm}
            tab={tab}
            values={values}
            mapKz={mapKz}
            mapIndex={mapIndex}
          />
        )}
      </CoeffParameters>

      <ConfirmDialog />
    </WrapperParameters>
  );
};
Form.displayName = 'Form';
