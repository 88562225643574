import { Button, MenuItem, Stack, Typography } from '@mui/material';
import { api } from 'api/api';
import {
  useWS,
  useWSLSR,
  WSFile,
  WSMessageLSRUploadResult,
  WSRequestBodyLSRUpload
} from 'api/web-socket';
import { Drawer } from 'components/Drawer';
import UploadForm, { uploadFileTypes } from 'components/UploadForm';
import { Form, FormikProvider } from 'formik';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { useForm } from 'hooks/useForm';
import { useProjectId } from 'hooks/useProjectId';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from 'store/store';
import { lsrFileStatusNames } from 'types';
import {
  StyledFolderIcon,
  StyledStatusSelect,
  UploadLSRFilesDrawerProps,
  UploadLSRSocketFilesDrawer
} from '.';
import { statusOptions } from '../Table/Table.constants';

const initialValues: UploadLSRSocketFilesDrawer = {
  status: null,
  files: null
};

export const UploadLSRFilesDrawer: FC<UploadLSRFilesDrawerProps> = (
  props
): JSX.Element => {
  const dispatch = useAppDispatch();

  const { connectionStatus } = useWS();
  const {
    uploadFileLsr,
    uploadFileLsrProgress,
    uploadFileLsrStatus,
    uploadFileLsrError,
    clearAll
  } = useWSLSR();
  const projectID = useProjectId();

  const [filesData, setFilesData] = useState<WSFile[] | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [errorData, setErrorData] = useState<WSMessageLSRUploadResult | null>(
    null
  );
  const [progressPercent, setProgressPercent] = useState<number>(0);
  const [progressTime, setProgressTime] = useState<string>('');
  const [isListFiles, setIsListFiles] = useState<boolean>(false);

  const isUploaded = useMemo(
    () => !!uploadFileLsrProgress,
    [uploadFileLsrProgress]
  );

  const onSubmit = (values: UploadLSRSocketFilesDrawer) => {
    if (connectionStatus !== 'Open') {
      enqueueSnackbar(
        'Установка соединения. Повторите попытку через несколько секунд.',
        {
          variant: 'error',
          autoHideDuration: 15000
        }
      );
    } else {
      if (values.files) {
        const fileWS: WSRequestBodyLSRUpload = {
          projectID: +projectID,
          files: filesData ?? [],
          status: values.status ?? undefined
        };
        setIsListFiles(false);
        uploadFileLsr(fileWS);
      }
    }
  };

  const { formik } = useForm({
    initialValues,
    onSubmit
  });

  const resetAndCloseDrawer = useCallback(() => {
    props.onClose();

    setTimeout(() => {
      formik.resetForm();
      setFilesData(null);
    }, 300);
  }, [formik, props]);

  const handleRefresh = useCallback(() => {
    dispatch(api.util.invalidateTags(['LSR', 'ProjectDashboard']));
    resetAndCloseDrawer();
  }, [dispatch, resetAndCloseDrawer]);

  const { enqueueSnackbar } = useSnackbar();

  const { openConfirm, ConfirmDialog } = useConfirmDialog({
    handleConfirm: (confirm) => {
      confirm && resetAndCloseDrawer();
    }
  });

  const closeConfirmDrawer = () => {
    if (isUploaded) {
      resetAndCloseDrawer();
    } else {
      formik.dirty ? openConfirm() : resetAndCloseDrawer();
    }
  };

  useEffect(() => {
    if (uploadFileLsrProgress) {
      setProgressTime(uploadFileLsrProgress.remaining);
      setProgressPercent(Math.floor(uploadFileLsrProgress.percent * 100));
    }
  }, [uploadFileLsrProgress]);

  useEffect(() => {
    if (uploadFileLsrStatus) {
      setErrorData(null);
      setProgressPercent(0);
      setProgressTime('');
      handleRefresh();
      clearAll();
    }
  }, [clearAll, handleRefresh, uploadFileLsrStatus]);

  useEffect(() => {
    if (uploadFileLsrError) {
      setError(true);
      setProgressPercent(0);
      setProgressTime('');
      setErrorData(uploadFileLsrError);
    }
  }, [uploadFileLsrError]);

  return (
    <>
      <Drawer
        title="Загрузить документы"
        open={props.open}
        onClose={closeConfirmDrawer}>
        <FormikProvider value={formik}>
          <Stack p={2.5} component={Form} height="100%" overflow="auto">
            <Stack direction="row" alignItems="center" spacing={2.5}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <StyledFolderIcon />
                <Typography variant="body2">Статус:</Typography>
              </Stack>
              <StyledStatusSelect
                value={formik.values.status}
                displayEmpty
                disabled={isUploaded}
                fullWidth
                renderValue={(selected) =>
                  selected === null
                    ? 'Выберите статус'
                    : lsrFileStatusNames[selected]
                }
                onChange={(e) =>
                  formik.setFieldValue('status', e.target.value)
                }>
                {statusOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </StyledStatusSelect>
            </Stack>
            <UploadForm
              handleOnReload={clearAll}
              errorsMSG={errorData}
              mode="xml"
              format={uploadFileTypes.xml}
              error={error}
              isLoading={isUploaded}
              setError={setError}
              setFormData={undefined}
              isWS={true}
              setFilesData={setFilesData}
              wsProgress={progressPercent}
              wsTime={progressTime}
              filesData={filesData}
              isListFiles={isListFiles}
              setIsListFiles={setIsListFiles}
            />
            <Stack direction="row" spacing={2} mt="auto">
              <Button
                color="success"
                type="submit"
                disabled={!filesData || isUploaded}
                fullWidth>
                Обработать
              </Button>
              <Button fullWidth onClick={closeConfirmDrawer}>
                Закрыть
              </Button>
            </Stack>
          </Stack>
        </FormikProvider>
      </Drawer>
      <ConfirmDialog />
    </>
  );
};
