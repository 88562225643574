/**
 * @author Mr_FabiozZz[mr.fabiozzz@gmail.com]
 */

import { ICoefficientData } from 'api/references/estimates/estimates.types';
import React from 'react';
import { Row } from './Row';

export const ListRow = React.memo(
  ({
    list,
    name,
    modifyKey,
    mapKz,
    mapIndex
  }: {
    list: ICoefficientData[];
    name: string;
    modifyKey: 'kz' | 'index';
    mapKz: any;
    mapIndex: any;
  }) => {
    return (
      <React.Fragment>
        {list?.map((row, index) => {
          return (
            <Row
              mapKz={mapKz}
              mapIndex={mapIndex}
              row={row}
              key={(row.title || '') + index}
              modifyKey={modifyKey}
              name={
                name ? name + '.' + index.toString() : '.' + index.toString()
              }
            />
          );
        })}
      </React.Fragment>
    );
  }
);
ListRow.displayName = 'ListRow';
