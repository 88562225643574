import { ReadyState } from 'react-use-websocket';
import { CalcData, CalcDataType, LSRFileStatus } from 'types';

export interface ProviderWSProps {
  children: React.ReactNode;
}

export interface WSContextProps {
  /**
   * @description Статус сокета - реальный
   */
  readyState: ReadyState;
  /**
   * @description Состояние авторизации сокета
   */
  isAuth: boolean;
  /**
   * @description Состояние открЫтИЯ сокета
   */
  isOpen: boolean;
  /**
   * @description Статус сокета - понятный
   */
  connectionStatus: ConnectionStatusType;
}

export interface WSContextLSRProps {
  uploadFileLsr: (props: WSRequestBodyLSRUpload) => void;
  uploadFileLsrProgress?: WSResponseBodyLSRUpload<WSTaskStatus.IN_PROGRESS>;
  uploadFileLsrStatus?: WSResponseBodyLSRUpload<WSTaskStatus.OK>;
  uploadFileLsrError?: WSResponseBodyLSRUpload<WSTaskStatus.ERROR>;
  uploadFileLsrCanceled?: boolean;
  clearUploadFileLsrProgress: () => void;
  clearUploadFileLsrStatus: () => void;
  clearUploadFileLsrCanceled: () => void;
  clearUploadFileLsrError: () => void;
  clearAll: () => void;
}

export interface WSContextHandbookProps {
  uploadFileHandbook: (props: WSRequestBodyHandbkUpload) => void;
  uploadFileHandbookProgress?: WSResponseBodyHandbkUpload<WSTaskStatus.IN_PROGRESS>;
  uploadFileHandbookStatus?: WSResponseBodyHandbkUpload<WSTaskStatus.OK>;
  uploadFileHandbookError?: WSResponseBodyHandbkUpload<WSTaskStatus.ERROR>;
  uploadFileHandbookCanceled?: boolean;
  clearUploadFileHandbookProgress: () => void;
  clearUploadFileHandbookStatus: () => void;
  clearUploadFileHandbookCanceled: () => void;
  clearUploadFileHandbookError: () => void;
  clearAll: () => void;
}

export interface WSContextCalcProps {
  createCalc: (props: WSRequestBodyLSRCreateCalc) => void;
  createCalcProgress?: WSResponseBodyLSRCreateCalc<WSTaskStatus.IN_PROGRESS>;
  createCalcStatus?: WSResponseBodyLSRCreateCalc<WSTaskStatus.OK>;
  createCalcError?: boolean;
  createCalcCanceled?: boolean;
  clearCalcProgress: () => void;
  clearCalcStatus: () => void;
  clearCalcCanceled: () => void;
  clearCalcError: () => void;
  clearAll: () => void;
}

export enum WSEventType {
  auth = 'AUTH',

  lsr_upload = 'LSR_UPLOAD',
  lsr_create_calc = 'LSR_CREATE_CALC',
  handbk_upload = 'HANDBK_UPLOAD',

  cancel_upload = 'CANCEL_UPLOAD'
}

export type ConnectionStatusType =
  | 'Connecting'
  | 'Open'
  | 'Closing'
  | 'Closed'
  | 'Uninstantiated';

export interface WSResponse<E extends WSEventType, S extends WSTaskStatus> {
  event: E;
  status: S;
  body: WSResponseBody<E, S>;
}

export type WSResponseBody<
  E extends WSEventType,
  S extends WSTaskStatus
> = E extends WSEventType.auth
  ? WSResponseBodyAuth<S>
  : E extends WSEventType.cancel_upload
    ? WSResponseBodyCancelUpload<S>
    : E extends WSEventType.handbk_upload
      ? WSResponseBodyHandbkUpload<S>
      : E extends WSEventType.lsr_create_calc
        ? WSResponseBodyLSRCreateCalc<S>
        : E extends WSEventType.lsr_upload
          ? WSResponseBodyLSRUpload<S>
          : never;

export type WSResponseBodyAuth<S extends WSTaskStatus> =
  S extends WSTaskStatus.OK
    ? null
    : S extends WSTaskStatus.IN_PROGRESS
      ? null
      : S extends WSTaskStatus.CANCELING
        ? null
        : S extends WSTaskStatus.CANCELED
          ? null
          : S extends WSTaskStatus.ERROR
            ? null
            : null;

export type WSResponseBodyCancelUpload<S extends WSTaskStatus> =
  S extends WSTaskStatus.OK
    ? null
    : S extends WSTaskStatus.IN_PROGRESS
      ? null
      : S extends WSTaskStatus.CANCELING
        ? null
        : S extends WSTaskStatus.CANCELED
          ? null
          : S extends WSTaskStatus.ERROR
            ? null
            : null;

export type WSResponseBodyHandbkUpload<S extends WSTaskStatus> =
  S extends WSTaskStatus.OK
    ? WSMessageHandbkResult
    : S extends WSTaskStatus.IN_PROGRESS
      ? WSMessageTaskProgress
      : S extends WSTaskStatus.CANCELING
        ? null
        : S extends WSTaskStatus.CANCELED
          ? null
          : S extends WSTaskStatus.ERROR
            ? null | WSMessageHandbkResult
            : null;

export type WSResponseBodyLSRCreateCalc<S extends WSTaskStatus> =
  S extends WSTaskStatus.OK
    ? CalcData
    : S extends WSTaskStatus.IN_PROGRESS
      ? WSMessageTaskProgress
      : S extends WSTaskStatus.CANCELING
        ? null
        : S extends WSTaskStatus.CANCELED
          ? null
          : S extends WSTaskStatus.ERROR
            ? null
            : null;

export type WSResponseBodyLSRUpload<S extends WSTaskStatus> =
  S extends WSTaskStatus.OK
    ? WSMessageLSRUploadResult
    : S extends WSTaskStatus.IN_PROGRESS
      ? WSMessageTaskProgress
      : S extends WSTaskStatus.CANCELING
        ? null
        : S extends WSTaskStatus.CANCELED
          ? null
          : S extends WSTaskStatus.ERROR
            ? null | WSMessageLSRUploadResult
            : null;

export enum WSTaskStatus {
  OK = 'OK',
  IN_PROGRESS = 'IN_PROGRESS',
  CANCELING = 'CANCELING',
  CANCELED = 'CANCELED',
  ERROR = 'ERROR'
}

export interface WSMessageTaskProgress {
  remaining: string;
  percent: number;
  completed: number;
  total: number;
}
export interface WSMessageHandbkResult {
  success: boolean;
  errors: { address: string; msg: string }[];
  errorRows: any;
}

export interface WSMessageLSRUploadResult {
  totalUploaded: number | null;
  withErrors: {
    name: string;
    success: boolean;
    msg: null | string;
  }[];
  uploaded: {
    name: string;
    success: boolean;
    msg: null | string;
  }[];
}

export interface WSRequest<E extends WSEventType> {
  event: E;
  body: WSRequestBody<E>;
}

export type WSRequestBody<E extends WSEventType> = E extends WSEventType.auth
  ? WSRequestBodyAuth
  : E extends WSEventType.cancel_upload
    ? WSRequestBodyCancelUpload
    : E extends WSEventType.handbk_upload
      ? WSRequestBodyHandbkUpload
      : E extends WSEventType.lsr_create_calc
        ? WSRequestBodyLSRCreateCalc
        : E extends WSEventType.lsr_upload
          ? WSRequestBodyLSRUpload
          : never;

export type WSRequestBodyAuth = { token: string };

export type WSRequestBodyCancelUpload = {
  projectID: number;
  taskType: WSEventType;
};

export type WSRequestBodyHandbkUpload = {
  file: { b64Content: string; name: string };
};

export type WSRequestBodyLSRCreateCalc = {
  projectID: number;
  body: {
    title: string;
    description: string;
    type: CalcDataType;
    lsrIds: number[];
  };
};

export type WSRequestBodyLSRUpload = {
  projectID: number;
  files: WSFile[];
  status?: LSRFileStatus;
};

export type WSFile = {
  b64Content: string;
  name: string;
};
